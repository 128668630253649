import React from "react";
import DueTasks from "./DueTasks";
import { Container } from "@mui/material";

const RecordActivities = () => {



  return (
    <Container>

      <DueTasks />

    </Container>
  );
};

export default RecordActivities;
