import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { isDebugEnabled } from "../utils/commonUtils";

function DateDialog({ open, onClose, onDateSelect }) {
  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize the selected date state

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update the selected date in state
    onClose();
    onDateSelect(date);
  };

  const handleOK = () => {
    onDateSelect(selectedDate); // Pass the selected date to the parent component
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <StaticDatePicker
          value={selectedDate}
          onAccept={handleDateChange}
          disableFuture={!isDebugEnabled()}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}

export default DateDialog;
