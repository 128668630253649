import React, { useState } from "react";
import { Box, Chip, Divider, Modal, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import dataFormatter from "../utils/dataFormatter";
import ImageGallery from "../components/ImageGallery";
import { ErrorBoundary } from "react-error-boundary";
import { SpecialActivityType, TaskAttributeType } from "../models";

const ThumbnailImage = styled("img")({
  width: "64px",
  height: "64px",
  objectFit: "cover",
  cursor: "pointer",
});

const EntityActivityContent = ({ activity }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  return (
    <Box >
      <Typography variant="body1"> {activity.taskactivity.missed ? "Routine Missed: " : activity.taskactivity.cancelled ? "Routine Cancelled: " : ""} {activity.taskactivity.comments}</Typography>
      {activity.taskactivity.images && activity.taskactivity.images.length > 0 && <Divider sx={{ marginBottom: "1em", paddingBottom: "1em" }}></Divider>}
      {activity.taskactivity.images && activity.taskactivity.images.length > 0 && (
        <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          <ErrorBoundary fallback={<div>Coulld not load images</div>}>
            <ImageGallery imagesMetadata={activity.taskactivity.images}></ImageGallery>
          </ErrorBoundary>
        </Box>
      )}
      {activity.taskactivity.attributes && activity.taskactivity.attributes.length > 0 && <Stack>
        {activity.taskactivity.attributes.map((attribute, i) => {
          if (attribute.attributeType == "CheckListItem") {
            const value = attribute.value ? attribute.value.replace("true", "yes")?.replace("false", "no") : "no";
            return <Stack direction="row" key={i}><Typography >{attribute.name} : </Typography> <Chip variant="outlined" size="small" label={value} color={value == "yes" ? "success" : "warning"}></Chip></Stack>
          }
          else return <Typography key={i}>{attribute.name} : {attribute.value} </Typography>
        })}
      </Stack>}
      {activity.taskactivity.specialType != SpecialActivityType.PATROL && <Box  >
        <Divider sx={{ marginBottom: "1em", paddingBottom: "1em" }}></Divider>
        {!activity.taskactivity.missed && <Typography variant="body2">{activity.system?.name}</Typography>}
        <Typography variant="body2">Routine :  {activity.title}  </Typography>
        <Typography variant="body2">Was Due on :{new Date(activity.taskactivity.dueDate).toLocaleDateString()}  </Typography>
        <Typography variant="body2">Schedule :  {dataFormatter.formatSchedule(activity.frequency, activity.schedule)}</Typography>
      </Box>}
      <Modal open={Boolean(selectedImage)} onClose={handleClosePopup}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={selectedImage}
            alt="selected"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default EntityActivityContent;
