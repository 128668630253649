/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      id
      name
      zones {
        items {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        nextToken
        startedAt
      }
      tasks {
        items {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        nextToken
        startedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      health
      health7days
      health30days
      tasksYesterday
      tasks7days
      tasks30days
      missedTaskYesterday
      missedTask7days
      missedTask30days
      lastUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSystems = /* GraphQL */ `
  query ListSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSystems = /* GraphQL */ `
  query SyncSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSystems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      id
      name
      systemId
      system {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      villas {
        items {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemZonesId
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        villas {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemZonesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncZones = /* GraphQL */ `
  query SyncZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncZones(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        villas {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemZonesId
      }
      nextToken
      startedAt
    }
  }
`;
export const getVilla = /* GraphQL */ `
  query GetVilla($id: ID!) {
    getVilla(id: $id) {
      id
      number
      zone {
        id
        name
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        villas {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemZonesId
      }
      residents {
        items {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      zoneVillasId
    }
  }
`;
export const listVillas = /* GraphQL */ `
  query ListVillas(
    $filter: ModelVillaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVillas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        zone {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        residents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        zoneVillasId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVillas = /* GraphQL */ `
  query SyncVillas(
    $filter: ModelVillaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVillas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        number
        zone {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        residents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        zoneVillasId
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      name
      picture
      phone
      email
      role
      villa {
        id
        number
        zone {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        residents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        zoneVillasId
      }
      tasks {
        items {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        nextToken
        startedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      villaResidentsId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      nextToken
      startedAt
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      title
      description
      frequency
      schedule {
        weeklyDays
        monthlyDay
        monthlyWeek
        monthlyWeekDay
        yearlyMonth
        yearlyDay
        recurrence
      }
      dueDays
      assignee {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      systemId
      system {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      images
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemTasksId
      userTasksId
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        frequency
        schedule {
          weeklyDays
          monthlyDay
          monthlyWeek
          monthlyWeekDay
          yearlyMonth
          yearlyDay
          recurrence
        }
        dueDays
        assignee {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        images
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTasksId
        userTasksId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTasks = /* GraphQL */ `
  query SyncTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        frequency
        schedule {
          weeklyDays
          monthlyDay
          monthlyWeek
          monthlyWeekDay
          yearlyMonth
          yearlyDay
          recurrence
        }
        dueDays
        assignee {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        images
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTasksId
        userTasksId
      }
      nextToken
      startedAt
    }
  }
`;
export const getTaskActivity = /* GraphQL */ `
  query GetTaskActivity($id: ID!) {
    getTaskActivity(id: $id) {
      id
      task {
        id
        title
        description
        frequency
        schedule {
          weeklyDays
          monthlyDay
          monthlyWeek
          monthlyWeekDay
          yearlyMonth
          yearlyDay
          recurrence
        }
        dueDays
        assignee {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        images
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTasksId
        userTasksId
      }
      systemId
      whoDid {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      dueDate
      graceDate
      when
      comments
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      missed
      cancelled
      delayed
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      approved
      specialType
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      anomalies {
        items {
          id
          attribute
          value
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          taskActivityAnomaliesId
          taskActivityBkAnomaliesId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemTaskActivitiesId
      userTaskActivitiesId
      taskTaskActivitiesId
    }
  }
`;
export const listTaskActivities = /* GraphQL */ `
  query ListTaskActivities(
    $filter: ModelTaskActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTaskActivities = /* GraphQL */ `
  query SyncTaskActivities(
    $filter: ModelTaskActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTaskActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      nextToken
      startedAt
    }
  }
`;
export const getTaskActivityBk = /* GraphQL */ `
  query GetTaskActivityBk($id: ID!) {
    getTaskActivityBk(id: $id) {
      id
      taskid
      systemId
      whoDidId
      dueDate
      graceDate
      when
      comments
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      missed
      cancelled
      delayed
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      approved
      specialType
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      anomalies {
        items {
          id
          attribute
          value
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          taskActivityAnomaliesId
          taskActivityBkAnomaliesId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTaskActivityBks = /* GraphQL */ `
  query ListTaskActivityBks(
    $filter: ModelTaskActivityBkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivityBks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskid
        systemId
        whoDidId
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTaskActivityBks = /* GraphQL */ `
  query SyncTaskActivityBks(
    $filter: ModelTaskActivityBkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTaskActivityBks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        taskid
        systemId
        whoDidId
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTaskActivityAnomaly = /* GraphQL */ `
  query GetTaskActivityAnomaly($id: ID!) {
    getTaskActivityAnomaly(id: $id) {
      id
      taskActivity {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      attribute
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      taskActivityAnomaliesId
      taskActivityBkAnomaliesId
    }
  }
`;
export const listTaskActivityAnomalies = /* GraphQL */ `
  query ListTaskActivityAnomalies(
    $filter: ModelTaskActivityAnomalyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivityAnomalies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskActivity {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        attribute
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        taskActivityAnomaliesId
        taskActivityBkAnomaliesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTaskActivityAnomalies = /* GraphQL */ `
  query SyncTaskActivityAnomalies(
    $filter: ModelTaskActivityAnomalyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTaskActivityAnomalies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        taskActivity {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        attribute
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        taskActivityAnomaliesId
        taskActivityBkAnomaliesId
      }
      nextToken
      startedAt
    }
  }
`;
export const getTaskActivityRemark = /* GraphQL */ `
  query GetTaskActivityRemark($id: ID!) {
    getTaskActivityRemark(id: $id) {
      id
      taskActivity {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      whoProvidedRemark {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      when
      remark
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRemarksId
      taskActivityRemarksId
      taskActivityBkRemarksId
    }
  }
`;
export const listTaskActivityRemarks = /* GraphQL */ `
  query ListTaskActivityRemarks(
    $filter: ModelTaskActivityRemarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivityRemarks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskActivity {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        whoProvidedRemark {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        when
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemarksId
        taskActivityRemarksId
        taskActivityBkRemarksId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTaskActivityRemarks = /* GraphQL */ `
  query SyncTaskActivityRemarks(
    $filter: ModelTaskActivityRemarkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTaskActivityRemarks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        taskActivity {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        whoProvidedRemark {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        when
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemarksId
        taskActivityRemarksId
        taskActivityBkRemarksId
      }
      nextToken
      startedAt
    }
  }
`;
export const getSecurityPatrol = /* GraphQL */ `
  query GetSecurityPatrol($id: ID!) {
    getSecurityPatrol(id: $id) {
      id
      date
      actualStartTime
      actualEndTime
      status
      scheduleStartTime
      routeScans {
        items {
          id
          patrolLocationId
          timeOfScan
          latitude
          longitude
          sequence
          status
          user
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          securityPatrolRouteScansId
        }
        nextToken
        startedAt
      }
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      onMygate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSecurityPatrols = /* GraphQL */ `
  query ListSecurityPatrols(
    $filter: ModelSecurityPatrolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecurityPatrols(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        actualStartTime
        actualEndTime
        status
        scheduleStartTime
        routeScans {
          nextToken
          startedAt
        }
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        onMygate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSecurityPatrols = /* GraphQL */ `
  query SyncSecurityPatrols(
    $filter: ModelSecurityPatrolFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSecurityPatrols(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        actualStartTime
        actualEndTime
        status
        scheduleStartTime
        routeScans {
          nextToken
          startedAt
        }
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        onMygate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRouteScan = /* GraphQL */ `
  query GetRouteScan($id: ID!) {
    getRouteScan(id: $id) {
      id
      patrol {
        id
        date
        actualStartTime
        actualEndTime
        status
        scheduleStartTime
        routeScans {
          nextToken
          startedAt
        }
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        onMygate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patrolLocationId
      timeOfScan
      latitude
      longitude
      sequence
      status
      user
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      securityPatrolRouteScansId
    }
  }
`;
export const listRouteScans = /* GraphQL */ `
  query ListRouteScans(
    $filter: ModelRouteScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRouteScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patrol {
          id
          date
          actualStartTime
          actualEndTime
          status
          scheduleStartTime
          onMygate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        patrolLocationId
        timeOfScan
        latitude
        longitude
        sequence
        status
        user
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        securityPatrolRouteScansId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRouteScans = /* GraphQL */ `
  query SyncRouteScans(
    $filter: ModelRouteScanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRouteScans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        patrol {
          id
          date
          actualStartTime
          actualEndTime
          status
          scheduleStartTime
          onMygate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        patrolLocationId
        timeOfScan
        latitude
        longitude
        sequence
        status
        user
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        securityPatrolRouteScansId
      }
      nextToken
      startedAt
    }
  }
`;
export const getPatrolLocation = /* GraphQL */ `
  query GetPatrolLocation($id: ID!) {
    getPatrolLocation(id: $id) {
      id
      name
      qrText
      latitude
      longitude
      sequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPatrolLocations = /* GraphQL */ `
  query ListPatrolLocations(
    $filter: ModelPatrolLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatrolLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        qrText
        latitude
        longitude
        sequence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPatrolLocations = /* GraphQL */ `
  query SyncPatrolLocations(
    $filter: ModelPatrolLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatrolLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        qrText
        latitude
        longitude
        sequence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTaskSummary = /* GraphQL */ `
  query GetTaskSummary($id: ID!) {
    getTaskSummary(id: $id) {
      id
      type
      startDate
      endDate
      createdDate
      systemSummary {
        systemID
        completed
        missed
        pending
        cancelled
      }
      activitySummary {
        taskID
        name
        completed
        missed
        pending
        cancelled
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTaskSummaries = /* GraphQL */ `
  query ListTaskSummaries(
    $filter: ModelTaskSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskSummaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        startDate
        endDate
        createdDate
        systemSummary {
          systemID
          completed
          missed
          pending
          cancelled
        }
        activitySummary {
          taskID
          name
          completed
          missed
          pending
          cancelled
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTaskSummaries = /* GraphQL */ `
  query SyncTaskSummaries(
    $filter: ModelTaskSummaryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTaskSummaries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        startDate
        endDate
        createdDate
        systemSummary {
          systemID
          completed
          missed
          pending
          cancelled
        }
        activitySummary {
          taskID
          name
          completed
          missed
          pending
          cancelled
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
