import React, { useState, useEffect } from "react";
import {
  TextField,
} from "@mui/material";

function TaskScheduleDaily({ schedule, onChange }) {
  const [dailyInterval, setDailyInterval] = useState(schedule.recurrence || 1);


  useEffect(() => {
    onChange({ ...schedule, recurrence: dailyInterval });
  }, [dailyInterval]);

  const handleIntervalChange = (event) => {
    const interval = parseInt(event.target.value);
    setDailyInterval(interval);
  };

  return (
    <>
      {/* <TextField
        label="Interval (days)"
        type="number"
        value={dailyInterval}
        onChange={handleIntervalChange}
        inputProps={{ min: 1 }}
        sx={{ mt: 2 }}
      /> */}
      {/* <TextField
        label="Time of day"
        type="time"
        value={dailyTime}
        onChange={handleTimeChange}
        sx={{ mt: 2 }}
      /> */}
    </>
  );
}

export default TaskScheduleDaily;
