import React, { useEffect, useState } from 'react';
import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { System, Task, TaskSummary, TaskSummaryType } from '../models';
import { TimeRange } from './enums'; // Import the enum
import StatusCard from './StatusCard';
import SplashScreen from '../components/SplashScreen';
import { Container, Grid, Tab, Tabs, Box, ToggleButton, ToggleButtonGroup, Stack, Typography } from '@mui/material';
import { DonutLarge, HorizontalRule, ListAlt, ViewList } from '@mui/icons-material';
import { DateTime } from 'luxon';
import TodayDashboard from './TodayDashboard';

function StatusPage() {
  const [systems, setSystems] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TimeRange.YESTERDAY); // Initially, display the first tab (Yesterday)
  const [selectedView, setSelectedView] = useState('Summary'); // Initially, display the "Summary" view
  const [taskSummary, setTaskSummary] = useState(null);

  useEffect(() => {
    // Define logic to query TaskSummary based on the selected time range
    async function fetchTaskSummary() {
      // Determine the start and end dates based on the time range
      let startDate, endDate;
      const yesterday = DateTime.now().minus({ days: 1 });

      if (selectedTab === TimeRange.LAST_7_DAYS) {
        startDate = new Date(yesterday.minus({ days: 7 }));
        endDate = new Date(yesterday);
      } else if (selectedTab === TimeRange.LAST_30_DAYS) {
        startDate = new Date(yesterday.minus({ days: 30 }));
        endDate = new Date(yesterday);
      } else {
        startDate = new Date(yesterday.minus({ days: 1 }));
        endDate = new Date(yesterday);
      }

      // Query the TaskSummary based on the determined dates
      const summaries = await DataStore.query(TaskSummary, (s) =>
        s.and(
          (task) => [
            task.startDate.eq(startDate.toISOString().split('T')[0]),
            task.endDate.eq(endDate.toISOString().split('T')[0]),
            task.type.eq(
              selectedTab === TimeRange.LAST_7_DAYS
                ? TaskSummaryType.WEEKLY
                : selectedTab === TimeRange.YESTERDAY
                  ? TaskSummaryType.DAILY
                  : TaskSummaryType.MONTHLY
            ),
          ]
        ),
        { sort: (s) => s.createdDate(SortDirection.DESCENDING) }
      );

      const updatedSummary = []
      if (summaries.length > 0) {
        await Promise.all(summaries[0].activitySummary.map(async (summary) => {
          const task = await DataStore.query(Task, summary.taskID)
          summary = { ...summary }
          summary.task = task
          updatedSummary.push(summary)
        }))

      }
      setTaskSummary(updatedSummary)
    }
    fetchTaskSummary();
  }, [selectedTab]);

  useEffect(() => {
    const subscription = DataStore.observeQuery(System).subscribe((snapshot) => {
      setSystems(snapshot.items);
    });
    return () => subscription.unsubscribe();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewChange = (event, newView) => {
    if (newView) {
      setSelectedView(newView);
    }
  };

  return (
    <Container sx={{ marginTop: '2rem' }}>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        {/* <Tab label="Today" value={TimeRange.TODAY} /> */}
        <Tab label="Yesterday" value={TimeRange.YESTERDAY} />
        <Tab label="7 days" value={TimeRange.LAST_7_DAYS} />
        <Tab label="30 days" value={TimeRange.LAST_30_DAYS} />
      </Tabs>
      {selectedTab == TimeRange.TODAY && <TodayDashboard></TodayDashboard>}
      {selectedTab != TimeRange.TODAY &&
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'fixed', bottom: '10px', right: '10px' }}>
            <ToggleButtonGroup
              value={selectedView}
              exclusive
              onChange={handleViewChange}
              size="small"
              aria-label="view selection"
            >
              <ToggleButton value="Summary" aria-label="summary view">
                <DonutLarge />
              </ToggleButton>
              <ToggleButton value="Details" aria-label="details view">
                <ViewList />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Grid container spacing={3}>
            {systems.map((system) => (
              (system.lastUpdated && <Grid item xs={12} sm={6} md={4} key={system.id}>
                <StatusCard
                  system={system}
                  timeRange={selectedTab}
                  view={selectedView}
                  taskSummary={taskSummary}
                />
              </Grid>)
            ))}
          </Grid>
          {selectedView == "Details" &&
            <Stack direction="row">
              <Stack direction="row"><HorizontalRule color='success'></HorizontalRule> <Typography sx={{ color: (theme) => theme.palette.success.main }}>Completed</Typography></Stack>
              <Stack direction="row"> <HorizontalRule color='error'></HorizontalRule> <Typography sx={{ color: (theme) => theme.palette.error.main }}>Missed</Typography></Stack>
              <Stack direction="row"> <HorizontalRule color='secondary'></HorizontalRule> <Typography sx={{ color: (theme) => theme.palette.secondary.main }}>Cancelled</Typography></Stack>
              <Stack direction="row"> <HorizontalRule color='primary'></HorizontalRule> <Typography sx={{ color: (theme) => theme.palette.primary.main }}>Pending</Typography></Stack>

            </Stack>}
        </>
      }
    </Container>
  );
}

export default StatusPage;
