import React, { useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { System, TaskActivity } from '../models';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Button, Divider } from '@mui/material';
import { deleteAllMissedTaskActivities, deleteAllPatrol, deleteAllTaskActivities, deleteAllTasks, deleteAllUpcomingTaskActivities } from '../utils/deleteTasks';


const UpcomingTaskSummary = () => {
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const today = new Date().toISOString().split('T')[0];
      const nextWeek = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      const nextMonth = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];

      const taskActivities = await DataStore.query(TaskActivity, (task) =>
        task.when.eq(null)
      );

     // const systemIDs = taskActivities.map((taskActivity) => taskActivity.systemId);
      const systems = await DataStore.query(System);

      const groupedData = taskActivities.reduce((groups, taskActivity) => {
        const systemID = taskActivity.systemId;
        const system = systems.find((s) => s.id === systemID);

        if (system && !groups[systemID]) {
          groups[systemID] = {
            systemName: system.name,
            dueToday: 0,
            dueNextWeek: 0,
            dueNextMonth: 0,
          };
        }

        if (taskActivity.dueDate === today) {
          groups[systemID].dueToday += 1;
        } else if (taskActivity.dueDate <= nextWeek) {
          groups[systemID].dueNextWeek += 1;
        } else if (taskActivity.dueDate <= nextMonth) {
          groups[systemID].dueNextMonth += 1;
        }

        return groups;
      }, {});

      setSummaryData(groupedData);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };
  const handleDeleteTaskActivities = () => {
    const confirmDelete = window.confirm('Are you sure you want to delete all activities?');
    if (confirmDelete) {
      deleteAllTaskActivities();
    }
  };
  const handleDeleteMissedActivities = () => {
    const confirmDelete = window.confirm('Are you sure you want to delete all missed activities?');
    if (confirmDelete) {
      deleteAllMissedTaskActivities();
    }
  };
  const handleDeleteUpcomingActivities = () => {
    const confirmDelete = window.confirm('Are you sure you want to delete all upcoming activities?');
    if (confirmDelete) {
      deleteAllUpcomingTaskActivities();
    }
  };
  const handleDeletePatrol = () => {
    const confirmDelete = window.confirm('Are you sure you want to delete all patrol?');
    if (confirmDelete) {
      deleteAllPatrol();
    }
  };

  return (
    <Container>
      &nbsp;
    <TableContainer component={Paper} sx={{marginBottom:"10px"}}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>System Name</TableCell>
          <TableCell>Due Today</TableCell>
          <TableCell>Due Next 7 Days</TableCell>
          <TableCell>Due Next 30 Days</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.values(summaryData).map((summary) => (
          <TableRow key={summary.systemName}>
            <TableCell>{summary.systemName}</TableCell>
            <TableCell>{summary.dueToday}</TableCell>
            <TableCell>{summary.dueNextWeek}</TableCell>
            <TableCell>{summary.dueNextMonth}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  {/* <Button onClick={deleteAllTasks} variant='outlined'>Delete all task definitions</Button> */}
  <Button onClick={handleDeleteTaskActivities} variant='outlined'>Delete all activities</Button>
  <Button onClick={handleDeleteMissedActivities} variant='outlined'>Delete Missed activities</Button>
  <Button onClick={handleDeleteUpcomingActivities} variant='outlined'>Delete Upcoming activities</Button>
  <Button onClick={handleDeletePatrol} variant='outlined'>Delete all Patrol</Button>
  </Container>
  );
};

export default UpcomingTaskSummary;
