import React, { useState, useEffect } from "react";
import {
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  Stack,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText, // Add FormHelperText component
} from "@mui/material";
import { TaskAttributeType } from "../models";

function TaskAttributes({ attributes, onAttributeChange, onValidate,showErrors }) {
  const [internalAttributes, setInternalAttributes] = useState([...attributes]);
  const [errors, setErrors] = useState({}); // State to manage errors

  const handleAttributeChange = (index, value) => {
    const updatedAttributes = [...internalAttributes];
    updatedAttributes[index] = { ...updatedAttributes[index] };
    updatedAttributes[index].value = value;
    setInternalAttributes(updatedAttributes);
    onAttributeChange(index, String(value));
    validateAttributesAndRender(updatedAttributes);
  };

  useEffect(()=>{
    if(!showErrors)onValidate(!silentlyCheckAttributes(internalAttributes));
    else validateAttributesAndRender(internalAttributes)
  },[showErrors])

  const silentlyCheckAttributes = (attributes) => {
    return attributes.some((attribute) => {
      return attribute.mandatory === true && !attribute.value;
    });
  };

  // Function to validate attributes and set errors
  const validateAttributesAndRender = (attributes) => {
    const newErrors = {};
    attributes.forEach((attribute, index) => {
      if (attribute.mandatory && (!attribute.value || (attribute.attributeType !== "CheckListItem" && attribute.value.trim() === ""))) {
        newErrors[index] = "This information is required"; // Set error message
      } else {
        newErrors[index] = null; // Clear the error message
      }
    });
    setErrors(newErrors);
    const hasErrors = Object.values(newErrors).some((error) => !!error);
    onValidate(!hasErrors); // Notify parent component of validation status
  };

  return (
    <div>
      <Divider>Information</Divider>
      <FormGroup>
        {internalAttributes &&
          internalAttributes.map((attribute, index) => (
            <div key={index}>
              {renderAttribute(attribute, index, handleAttributeChange)}
              {errors[index] && (
                <FormHelperText error>{errors[index]}</FormHelperText>
              )}
            </div>
          ))}
      </FormGroup>
    </div>
  );

  function renderAttribute(attribute, index, handleAttributeChange) {
    const onValueChange = (e) => {
      const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
      handleAttributeChange(index, value);
    };

    const label = attribute.name;
    const helperText = attribute.label || "";

    const listItemValues = helperText.split(",").map((item) => item.trim());

    return (
      <Stack direction="column">
        {attribute.attributeType === "CheckListItem" ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={attribute.value === "true" || attribute.value === true}
                onChange={onValueChange}
              />
            }
            label={attribute.label || attribute.name}
          />
        ) : attribute.attributeType === "ListItem" ? (
          <FormControl margin="normal">
            <InputLabel>{attribute.name}</InputLabel>
            <Select
              label={label}
              value={attribute.value||""}
              onChange={onValueChange}
              //helperText={helperText}
              error={!!errors[index]} // Set error state
            >
              <MenuItem key="null" value="">
                  {" "}
                </MenuItem>
              {listItemValues.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl margin="normal">
            <TextField
              label={label}
              type={
                attribute.attributeType === "Number"
                  ? "number"
                  : attribute.attributeType === "Time"
                  ? "time"
                  : "text"
              }
              onChange={onValueChange}
              value={attribute.value||""}
              helperText={helperText}
              error={!!errors[index]} // Set error state
            />
          </FormControl>
        )}
      </Stack>
    );
  }
}

export default TaskAttributes;
