import { DataStore } from '@aws-amplify/datastore';
import { SecurityPatrol, Task, TaskActivity } from '../models';

export async function deleteAllTasks() {
  try {
    const tasks = await DataStore.query(Task);
    await Promise.all(tasks.map(task => DataStore.delete(task)));
    console.log('All task definitions deleted successfully.');
  } catch (error) {
    console.error('Error deleting tasks:', error);
  }
}

export async function deleteAllTaskActivities() {
  try {
    const activities = await DataStore.query(TaskActivity);
    await Promise.all(activities.map(task => DataStore.delete(task)));
    console.log('All activities deleted successfully.');
  } catch (error) {
    console.error('Error deleting tasks:', error);
  }
}

export async function deleteAllMissedTaskActivities() {
  try {
    const activities = await DataStore.query(TaskActivity,t=>t.missed.ne(null));
    console.log('Deleting missed activities :.',activities.length);
    await Promise.all(activities.map(task => DataStore.delete(task)));
    console.log('All missed activities deleted successfully.');
  } catch (error) {
    console.error('Error deleting tasks:', error);
  }
}
export async function deleteAllUpcomingTaskActivities() {
  try {
    const activities = await DataStore.query(TaskActivity,t=>t.when.eq(null));
    console.log('Deleting upcoming activities :.',activities.length);
    await Promise.all(activities.map(task => DataStore.delete(task)));
    console.log('All upcoming activities deleted successfully.');
  } catch (error) {
    console.error('Error deleting tasks:', error);
  }
}

export async function deleteAllPatrol() {
  try {
    const activities = await DataStore.query(SecurityPatrol);
    await Promise.all(activities.map(task => DataStore.delete(task)));
    console.log('All SecurityPatrol deleted successfully.');
  } catch (error) {
    console.error('Error deleting SecurityPatrol:', error);
  }
}

