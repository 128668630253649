import { List } from "@mui/material";
import TaskListItem from "./TaskListItem";
import { useEffect, useState } from "react";


const TaskList = ({ tasks,canManage }) => {

  return (
    <List>
      {tasks.map((task) => (
        <TaskListItem key={task.id} task={task} canManage={canManage}/>
      ))}
    </List>
  );
};

export default TaskList;
