import { CircularProgress, LinearProgress } from "@mui/material";
import { Hub } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";

//const progressRoot = document.getElementById('ds-progress-root');

export default class DSProgressIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outboxHasData: false,
      dataSynched: false,
      hasError: false,
    };
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   console.log("should update?",nextProps,nextState);
  //   return true
  // }
  listenForDatastoreEvent() {
    console.log("Listening for datastore events");
    Hub.listen("datastore", async (hubData) => {
      console.log(hubData);
      const { event, data } = hubData.payload;
      if (event === "outboxStatus") {
        console.log(`User has a outbox queue: ${data.isEmpty}`);
        //setOutboxHasData(!data.isEmpty);
        this.setState({
          outboxHasData: !data.isEmpty,
        });
      }
      if (event === "syncQueriesReady") {
        console.log(`Data synched from cloud`);
        //setDataSynched(true);
        this.setState({
          dataSynched: true,
        });
      }
    });
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log((error, errorInfo));
  }
  componentDidMount() {
    this.listenForDatastoreEvent();
  }

  render() {
    if (this.state.hasError) return <></>;
    return (
      <>
        {(this.state.outboxHasData || !this.state.dataSynched) && (
          <CircularProgress
            size={20}
            sx={{ color: "white" }}
          ></CircularProgress>
        )}
      </>
    );
  }
}