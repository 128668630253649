// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserRole = {
  "RESIDENT": "Resident",
  "FM": "FM",
  "EMPLOYEE": "Employee",
  "ADMIN": "Admin",
  "RWA": "RWA"
};

const TaskFrequency = {
  "HOURLY": "hourly",
  "DAILY": "daily",
  "WEEKLY": "weekly",
  "MONTHLY": "monthly",
  "QUARTERLY": "quarterly",
  "YEARLY": "yearly",
  "ONETIME": "onetime"
};

const WeekDay = {
  "MON": "MON",
  "TUE": "TUE",
  "WED": "WED",
  "THU": "THU",
  "FRI": "FRI",
  "SAT": "SAT",
  "SUN": "SUN"
};

const SpecialActivityType = {
  "PATROL": "Patrol"
};

const TaskAttributeType = {
  "CHECK_LIST_ITEM": "CheckListItem",
  "TEXT": "Text",
  "TIME": "Time",
  "NUMBER": "Number",
  "LIST_ITEM": "ListItem"
};

const SecurityPatrollingStatus = {
  "NOT_STARTED": "NOT_STARTED",
  "STARTED": "STARTED",
  "COMPLETED": "COMPLETED",
  "COMPLETED_IN_MYGATE": "COMPLETED_IN_MYGATE"
};

const RouteScanStatus = {
  "YES": "YES",
  "NO": "NO",
  "NOQR": "NOQR"
};

const TaskSummaryType = {
  "DAILY": "DAILY",
  "WEEKLY": "WEEKLY",
  "MONTHLY": "MONTHLY"
};

const { System, Zone, Villa, User, Task, TaskActivity, TaskActivityBk, TaskActivityAnomaly, TaskActivityRemark, SecurityPatrol, RouteScan, PatrolLocation, TaskSummary, TaskSchedule, CustomAttribute, Image, SystemSummary, ActivitySummary } = initSchema(schema);

export {
  System,
  Zone,
  Villa,
  User,
  Task,
  TaskActivity,
  TaskActivityBk,
  TaskActivityAnomaly,
  TaskActivityRemark,
  SecurityPatrol,
  RouteScan,
  PatrolLocation,
  TaskSummary,
  UserRole,
  TaskFrequency,
  WeekDay,
  SpecialActivityType,
  TaskAttributeType,
  SecurityPatrollingStatus,
  RouteScanStatus,
  TaskSummaryType,
  TaskSchedule,
  CustomAttribute,
  Image,
  SystemSummary,
  ActivitySummary
};