import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";

const TaskScheduleMonthly = ({ schedule, onChange }) => {
  const [monthDays, setMonthDays] = useState(schedule.monthlyDay || []);
  const [recurringMonth, setRecurringMonth] = useState(schedule.recurrence || 1);

  useEffect(() => {
    onChange({ monthlyDay: monthDays, recurrence: recurringMonth });
  }, [monthDays, recurringMonth]);

  const handleDayOfMonthChange = (event) => {
    const dayOfMonth = parseInt(event.target.value);
    const newMonthDays = monthDays.includes(dayOfMonth)
      ? monthDays.filter((day) => day !== dayOfMonth)
      : [...monthDays, dayOfMonth];
    setMonthDays(newMonthDays);
  };

  const handleRecurringMonthChange = (event) => {
    const newRecurringMonth = parseInt(event.target.value);
    setRecurringMonth(newRecurringMonth);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormGroup row>
            {[...Array(31)].map((_, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={monthDays.includes(index + 1)}
                    onChange={handleDayOfMonthChange}
                    value={index + 1}
                  />
                }
                label={index + 1}
              />
            ))}
          </FormGroup>
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            label="Recurring every month(s)"
            type="number"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            margin="normal"
            value={recurringMonth}
            onChange={handleRecurringMonthChange}
          />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default TaskScheduleMonthly;
