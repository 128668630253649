// PeriodicMaintenanceCard.js
import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';
import MiniTaskActivity from './MiniTaskActivity';
import { EventRepeat, Today } from '@mui/icons-material';

const PeriodicMaintenanceCard = ({tasks}) => {
  return (
    <Box >
       <Divider sx={{marginTop:".5em",paddingTop :".25em",paddingBottom :"1em"}}><Stack direction="row" alignItems="center" sx={{color:(theme)=>theme.palette.primary.main}}><Today></Today> Periodic Maintenance Tasks</Stack> </Divider>
      <Grid container spacing={2}>
        {tasks.map(({ task, taskActivity }) => (
          <Grid item key={taskActivity.id} xs={6}>
            <MiniTaskActivity task={task} taskActivity={taskActivity} showDue={true}/>
          </Grid>
        ))}
      </Grid>
      {tasks.length <=0 && <Card><Typography >No tasks found</Typography></Card>}
    </Box>
  );
};

export default PeriodicMaintenanceCard;
