import { EventRepeat } from "@mui/icons-material";
import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import MiniTaskActivity from "./MiniTaskActivity";

function DailyActivitiesCard({ tasks }) {

 
  return (
    <Box sx={{paddingTop :".25em",paddingBottom :".25em"}}>
    <Divider sx={{paddingTop :".25em",paddingBottom :"1em"}}><Stack direction="row" alignItems="center" sx={{color:(theme)=>theme.palette.success.main}}><EventRepeat></EventRepeat> Daily Tasks</Stack> </Divider>
    <Grid container spacing={2}>
      {tasks.map(({ task, taskActivity }) => (
        <Grid item key={task.id} xs={6}>
          <MiniTaskActivity task={task} taskActivity={taskActivity} />
        </Grid>
      ))}
    </Grid>
    {tasks.length <=0 && <Card><Typography>No tasks found</Typography></Card>}
  </Box>
  );
}

export default DailyActivitiesCard;
