import { Storage } from "aws-amplify";
import { useState } from "react";
import FileUploader from "./FileUploader";
// src/pages/Home.js
function Home() {
  const [selectedFile, setSelectedFile] = useState(null);

  function handleFileInput(event) {
    setSelectedFile(event.target.files[0]);
  }
  function handleFileUpload() {
    Storage.put(selectedFile.name, selectedFile)
      .then(() => console.log('File uploaded successfully'))
      .catch(err => console.log('Error uploading file:', err));
  }

  return (
    // <div className="App">
    //   <h1>Amplify Storage Demo</h1>
    //   <input type="file" onChange={handleFileInput} />
    //   <button onClick={handleFileUpload}>Upload</button>
    // </div>
    <div> <FileUploader onFileUploaded={(urls)=>console.log("Completed upload",urls)} onUploadInit={(files)=>console.log("selected files",files)}></FileUploader> </div>
  );
}

export default Home;
