import React, { useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Task, TaskSummary, TaskSummaryType } from '../models';
import { Box, Chip, Divider, Paper, Stack, Typography } from '@mui/material';
import { TimeRange } from '../pages/enums';

const COMPLETED = "Completed";
const MISSED = "Missed";
const CANCELLED = "Cancelled";
const PENDING = "Pending";
function TaskSummaryComponent({ systemID, taskSummary }) {
    const thisSystemTask = taskSummary?.filter((summary) => summary.task?.systemId === systemID)


    return (
        <Box width="100%">

            {thisSystemTask.map((task) => (
                    <SingleTaskSummary task={task} key={task.id}/>

            ))}

        </Box>
    );
}


function SingleTaskSummary({ task }) {

    function removeDuplicates(array) {
        if (!array || array.length <= 0) return []
        const uniqueDates = new Set();

        return array.filter((date) => {
            if (!uniqueDates.has(date)) {
                uniqueDates.add(date);
                return true; // Keep the date in the array
            }
            return false; // Skip the date (it's a duplicate)
        });

    }
    // convert an array of dates into array of {date,type}
    function convertArray(array, type) {
        return array.map((date) => ({ date, type }))
    }

    //remove duplicates and add to single array
    const arrayToRender = []
    arrayToRender.push(...convertArray(removeDuplicates(task.completed), COMPLETED))
    arrayToRender.push(...convertArray(removeDuplicates(task.cancelled), CANCELLED))
    arrayToRender.push(...convertArray(removeDuplicates(task.missed), MISSED))
    arrayToRender.push(...convertArray(removeDuplicates(task.pending), PENDING))

    arrayToRender.sort((a, b) => new Date(a.date) - new Date(b.date));



    return (
        <>
            {task.task && <Stack direction={'column'} key={task.taskID}><Typography>{task.task?.title} </Typography> </Stack>
            }
            {arrayToRender.length > 0 && (
                arrayToRender.map((item) => (
                    <TaskDate key={item.date} date={item.date} type={item.type} />
                ))
            )}
        </>
    );
}

function TaskDate({ type, date }) {

    function getColor(type) {
        if (type == COMPLETED) return "success";
        if (type == MISSED) return "error";
        if (type == CANCELLED) return "secondary";
        if (type == PENDING) return "primary";
    }
    const color = getColor(type)
    return (
        <Chip key={date} label={formatDate(date)} variant="outlined" size='small' color={color} sx={{ fontSize: ".6em" }}></Chip>

    );
}

function formatDate(date) {
    // Format the date as needed
    return new Date(date).toLocaleDateString();
}

export default TaskSummaryComponent;
