import React, { useState, useEffect, useReducer } from "react";
import {
    Box,
    Typography,
    Link,
    Button,
    Stack,
} from "@mui/material";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Task, TaskActivity, System } from "../models";
import { DateTime } from "luxon";
import DailyActivitiesCard from "./DailyActivitiesCard";
import PeriodicMaintenanceCard from "./PeriodicMaintenanceCard";
import DateDialog from "./DateDialog";
import SystemSelectionDialog from "./SystemSelectionDialog"; // Import the SystemSelectionDialog component
import { Link as RouterLink } from "react-router-dom";
import { getDateDisplay } from "../utils/dateUtility";
import { Filter, FilterAlt, FilterAltOff } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import { CalendarIcon } from "@mui/x-date-pickers";
import fetchTodaysTasks from "../utils/taskFetcher";
import SplashScreen from "../components/SplashScreen";
import { set } from "date-fns";

function TodaysAction() {
    const [dailyTasks, setDailyTasks] = useState([]);
    const [periodicTasks, setPeriodicTasks] = useState([]);
    const [update, forceUpdate] = useReducer((x) => x + 1, 0);
    const [dateFilterOpen, setDateFilterOpen] = useState(false);
    const [systemFilterOpen, setSystemFilterOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedSystems, setSelectedSystems] = useState(readSystemFilter);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const subscription = listenForChanges();
        return () => {
            subscription && subscription.unsubscribe();
        };

    }, []);

    useEffect(() => {
        // Save selected systems to local storage whenever it changes
        localStorage.setItem("selectedSystems", JSON.stringify(selectedSystems));
    }, [selectedSystems]);

    useEffect(() => {
        fetchTodaysTasks(selectedSystems, selectedDate).then(({ pTask, dTask }) => {
            setDailyTasks(dTask);
            setPeriodicTasks(pTask);
            setLoading(false);
        })

        // Start checking
        const timerId = { current: null };
        // Function to recursively check for date changes
        function checkDateChange() {
            // Get the current date
            var currentDate = new Date().getDate();

            // Compare with the previous date
            if (currentDate !== selectedDate.getDate()) {
                console.log("Date has changed", currentDate, selectedDate.getDate());
                setSelectedDate(new Date());
            }
            else console.log("Date didnot change", currentDate, selectedDate.getDate());
            // Call check again after a short delay
            timerId.current = setTimeout(checkDateChange, 5 * 60 * 1000); // Check every 5 minute
        }

        // Start checking
        timerId.current = setTimeout(checkDateChange, 5 * 60 * 1000); // Check every 5 minute

        // Cleanup function to remove the timer when unmounting
        return () => {
            if (timerId.current )clearTimeout(timerId.current);
        };
        

    }, [update, selectedDate, selectedSystems]);





    function readSystemFilter() {
        const storedSelectedSystems = localStorage.getItem("selectedSystems");
        if (storedSelectedSystems) {
            return JSON.parse(storedSelectedSystems);
        }
        else return []
    }

    function listenForChanges() {
        const subscription = DataStore.observeQuery(TaskActivity).subscribe((snapshot) => {
            console.log("data changed");
            // Refetch data when changes occur
            fetchTodaysTasks(selectedSystems, selectedDate).then(({ pTask, dTask }) => {
                setDailyTasks(dTask);
                setPeriodicTasks(pTask);
            })
        });
        return subscription;
    }

    const handleDateSelect = (date) => {
        setSelectedDate(date);
        setDateFilterOpen(false);
    };

    const handleSystemSelect = (systemId) => {
        const updatedSystems = selectedSystems.includes(systemId)
            ? selectedSystems.filter((id) => id !== systemId)
            : [...selectedSystems, systemId];
        setSelectedSystems(updatedSystems);
    };

    const linkStyle = {
        fontSize: ".8em",
        textDecoration: "none",
        color: "#1976d2",
    };

    return (
        <>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', width: '100%' }}>
                    <SplashScreen fullscreen={false}></SplashScreen>
                </Box>
            )}

            <Box sx={{ paddingTop: "1em" }}>
                {!loading && (
                    <>
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" paddingRight="1em">
                            <Link style={linkStyle} component={RouterLink} onClick={() => setSystemFilterOpen(true)} >
                                {selectedSystems.length > 0 && <Stack direction={"row"} alignItems="center" sx={{ color: red[500] }}><FilterAlt fontSize=".9em"></FilterAlt> Filtered </Stack>}
                                {selectedSystems.length == 0 && <Stack direction={"row"} alignItems="center"><FilterAltOff fontSize=".9em"></FilterAltOff> Not Filtered </Stack>}
                            </Link>
                            <Link style={linkStyle} component={RouterLink} onClick={() => setDateFilterOpen(true)}>
                                <Stack direction={"row"} alignItems="center"><CalendarIcon fontSize=".9em"></CalendarIcon>  {selectedDate ? getDateDisplay(selectedDate) : "Today"} </Stack>
                            </Link>
                        </Stack>

                        <DailyActivitiesCard tasks={dailyTasks} />
                        <PeriodicMaintenanceCard tasks={periodicTasks} />
                    </>
                )}

                <DateDialog open={dateFilterOpen} onClose={() => setDateFilterOpen(false)} onDateSelect={handleDateSelect} />
                <SystemSelectionDialog
                    open={systemFilterOpen}
                    onClose={() => setSystemFilterOpen(false)}
                    selectedSystems={selectedSystems}
                    onSystemSelect={handleSystemSelect}
                    onClearSelections={() => setSelectedSystems([])}
                />
            </Box>
        </>
    );
}

export default TodaysAction;
