import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const RemarksButton = styled(Button)({
  backgroundColor: '#f44336',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#d32f2f',
  },
});

const EntityRemarks = ({ activity, setActivity }) => {
  const [remarks, setRemarks] = useState(activity.remarks || '');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleRemarksSubmit = () => {
    const updatedActivity = {
      ...activity,
      remarks,
      remarkBy: 'John Doe', // replace with actual user name
      remarkDate: new Date(),
    };
    setActivity(updatedActivity);
    setIsPopupOpen(false);
  };

  if (activity.missed && !activity.remarks) {
    return (
      <>
        <RemarksButton variant="contained" onClick={() => setIsPopupOpen(true)}>
          Provide remarks
        </RemarksButton>
        <Modal open={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Card sx={{ width: 400 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Provide remarks
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <textarea
                    value={remarks}
                    onChange={handleRemarksChange}
                    rows={5}
                    style={{ width: '100%' }}
                  />
                </Typography>
              </CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={handleRemarksSubmit}
                  disabled={!remarks}
                >
                  Submit
                </Button>
              </Box>
            </Card>
          </Box>
        </Modal>
      </>
    );
  }

  if (activity.remarks) {
    return (
      <Box sx={{ border: '1px solid #ccc', borderRadius: 4, p: 1 }}>
        <Typography variant="body2" color="text.secondary">
          {activity.remarks}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography variant="caption" color="text.secondary">
            {`Remarks by ${activity.remarkBy} on ${new Date(
              activity.remarkDate
            ).toLocaleString()}`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return null;
};

export default EntityRemarks;
