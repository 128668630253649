import { styled } from '@mui/material/styles';

export const Needle = styled('div')(({ score ,title,color}) => {
  const angle = ((score - 1) / 9) * 100 - 45;
  console.log(title,score,angle)
  
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transformOrigin: 'bottom center',
    transform: `rotate(${angle}deg)`,
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: `70px solid ${color}`,
    transition: 'all 0.5s ease-out'
  };
});
