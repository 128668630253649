import React, { useState, useEffect } from 'react';
import QRScanner from './QRScanner';
import { calculateDistance, getLocation, generateGoogleMapsLink } from './locationUtil';

const ScanController = () => {
  const [scanResult, setScanResult] = useState(null);
  const [location, setLocation] = useState(null);
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    if (location) {
      const selectedLat = location.latitude;
      const selectedLon = location.longitude;
      const fixedLat = 12.857029;
      const fixedLon = 77.8088261;
      const calculatedDistance = calculateDistance(selectedLat, selectedLon, fixedLat, fixedLon);
      setDistance(calculatedDistance);
    }
  }, [location]);

  const handleScan = (result) => {
    setScanResult(result);
  };

  const handleLocation = (location) => {
    setLocation(location);
  };

  return (
    <div>
      <button onClick={() => getLocation(handleLocation)}>Get Location</button>
      {location && (
        <div>
          Latitude: {location.latitude}, Longitude: {location.longitude}
          <a
            href={generateGoogleMapsLink(location.latitude, location.longitude)}
            target="_blank"
            rel="noopener noreferrer"
          >
            View in Google Maps
          </a>
        </div>
      )}
      {distance && <p>Distance from fixed location: {distance} m</p>}
      <QRScanner onScan={handleScan} />
      {scanResult && (
        <div>
          <h2>Scan Result</h2>
          <p>Data: {scanResult.data}</p>
        </div>
      )}
    </div>
  );
};

export default ScanController;
