import React, { useState, useEffect } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { System } from "../models";
import SystemCard from "./SystemCard";
import { Container } from "@mui/material";
import { Button, Fab, Grid, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";


const ManageSystems = () => {
  const [systems, setSystems] = useState([]);

  useEffect(() => {
    const fetchSystems = async () => {
      const fetchedSystems = await DataStore.query(System);
      setSystems(fetchedSystems);
    };
    fetchSystems();
  }, []);

  const handleAddSystem = async () => {
    const name = prompt("Enter system name:");
    if (!name) return;

    const newSystem = await DataStore.save(new System({ name }));
    setSystems([...systems, newSystem]);
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Fab color="primary" onClick={handleAddSystem} sx={{ position: 'fixed', bottom: '32px', right: '32px' }}>
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              {systems.map((system) => (
                <Grid item xs={12} sm={6} md={4} key={system.id}>
                  <SystemCard system={system} />
                </Grid>
              ))}
              {systems.length === 0 && (
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>No systems created yet</Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ManageSystems;
