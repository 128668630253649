import React, { useState, useRef, useEffect } from "react";
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { Box, IconButton, CircularProgress } from '@mui/material';
import { CameraAltOutlined, Error, ImageOutlined } from '@mui/icons-material';
import FilePreview from './FilePreview';
import { createThumbnail, getImageMetadata } from "../utils/imageUtils";
import { ImageLoading } from "../components/ImageGallery";

function FileUploader({ onFileUploaded, onUploadInit, onUploadComplete,existingFiles }) {
  const [files, setFiles] = useState([]);
  const [filesMetaData, setFilesMetadata] = useState(existingFiles||[]);
  const [uploadErrors, setUploadErrors] = useState([]);

  const uploadingString = "uploading";

  const filesMetaDataRef = useRef(filesMetaData); // Create a mutable ref

  const pendingFiles = useRef([]);

  const handleBeforeUnload = (event) => {

    if (pendingFiles.current.length > 0) {
      // Display confirmation message
      const confirmationMessage = "Your file is still being uploaded. Are you sure you want to leave?";

      event.preventDefault();
      event.returnValue = confirmationMessage; // This is required for Chrome
      return confirmationMessage; // This is required for Firefox
    }
  };

  useEffect(() => {
    
    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    // return () => {
    //   window.removeEventListener("beforeunload", handleBeforeUnload);
    // };
  }, []);

  // this is what happens after a file is uploaded
  const processFileUploadedEvent = async (localFile, storedFile) => {
    console.log("Uploaded file", storedFile);
    const metadata = await getImageMetadata(localFile);
    console.log("Image Metadata:", metadata);
    const existingEntryIndex = filesMetaDataRef.current.findIndex((entry) => entry.localFile === localFile.name);
    if (existingEntryIndex !== -1) {
      filesMetaDataRef.current[existingEntryIndex] = { key: storedFile.key, ...metadata, localFile: localFile.name };
    } else {
      //newSetMetadata.push({ key: stored.key, ...metadata, localFile: file.name });
      // this happens when user deletes a file while being uploaded .. ignore
    }
    setFilesMetadata([...filesMetaDataRef.current])
    onFileUploaded(filesMetaDataRef.current);

    try {
      pendingFiles.current = pendingFiles.current.filter((file) => file !== localFile);
      //console.log("Pending ",pendingFiles.current)
      if (onUploadComplete && pendingFiles.current.length == 0) {
        console.log("All files uploaded ")
        onUploadComplete(filesMetaDataRef.current);
      }
    } catch (e) {
      console.log(e)
    }
  }
  const handleFileChange = async (event) => {
    console.log("got more files");
    const selectedFiles = event.target.files;
    pendingFiles.current = [...selectedFiles] // start tracking 
    const fileArray = Array.from(selectedFiles);
    const allFiles = [...files, ...fileArray];

    setFiles(allFiles);

    const newSetMetadata = [];
    fileArray.map(async (file, index) => {
      newSetMetadata.push({ key: uploadingString, localFile: file.name });
    });
    const existingFilesMetadata = filesMetaData;// save for later use
    setFilesMetadata([...existingFilesMetadata, ...newSetMetadata]); // setup for temporary rendering
    filesMetaDataRef.current = [...existingFilesMetadata, ...newSetMetadata] // this will be used by call backs
    onUploadInit && onUploadInit([...filesMetaData, ...newSetMetadata]);
    // now start uploading

    const uploadPromises = fileArray.map(async (file, index) => {
      const id = uuidv4();
      const thumbnailName = `thumbnail-${id}-${file.name}`;
      const fileName = `${id}-${file.name}`;
      try {
        const thumbnail = await createThumbnail(file);
        await uploadFileWithRetry(thumbnailName, thumbnail, file.type, () => { console.log("thumpnail uploaded") }); // no wait
        uploadFileWithRetry(fileName, file, file.type, processFileUploadedEvent); // no wait
        //console.log("Uploaded thumbnail", stored);
      } catch (error) {
        console.error("Upload failed:", error);
        setUploadErrors((prevErrors) => [...prevErrors, index]);
      }
    });
    // await Promise.all(uploadPromises);
    // setFilesMetadata([...existingFilesMetadata, ...newSetMetadata]);
    // onFileUploaded([...existingFilesMetadata, ...newSetMetadata]);
  };

  const waitForNetworkAvailability = () => {
    return new Promise((resolve) => {
      const checkNetworkStatus = () => {
        if (navigator.onLine) {
          resolve();
          window.removeEventListener('online', checkNetworkStatus);
        }
      };

      window.addEventListener('online', checkNetworkStatus);
    });
  };

  const isNetworkError = (error) => {
    return !navigator.onLine;
  };

  const uploadFileWithRetry = async (fileName, file, contentType, callback) => {
    const maxRetries = 10;
    let retryCount = 0;

    while (retryCount < maxRetries) {
      try {
        const stored = await Storage.put(fileName, file, { contentType });
        callback(file, stored);
        return; // Exit the function after successful upload
      } catch (error) {
        console.error("Upload error:", error);
        retryCount++;

        if (isNetworkError(error)) {
          await waitForNetworkAvailability();
        }
      }
    }

    throw new Error(`File upload failed after ${maxRetries} attempts.`);
  };

  const handleRemove = async (index) => {
    const fileUrl = filesMetaData[index].key;
    const newUrls = [...filesMetaData];
    newUrls.splice(index, 1);
    setFilesMetadata(newUrls);
    onFileUploaded(newUrls);
    await Storage.remove(fileUrl);
  };

  const renderFilePreview = (filemetadata, index) => {
    if (uploadErrors.includes(index)) {
      return (
        <Box
          key={index}
          sx={{
            position: 'relative',
            width: 64,
            height: 64,
            borderRadius: '50%',
            backgroundColor: '#f0f0f0',
          }}
        >
          <ImageOutlined
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: 64,
              color: "grey"
            }}
          />
          <Error
            size={64}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        </Box>
      )
    }

    if (filemetadata.key == uploadingString) {

      return (
        <ImageLoading key={index} onRemove={() => handleRemove(index)} />
      );
    }
    return (
      <FilePreview
        key={index}
        fileKey={filemetadata.key}
        onRemove={() => handleRemove(index)}
      />
    );
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
        {filesMetaData.map((filemetadata, index) =>
          renderFilePreview(filemetadata, index)
        )}
      </Box>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-upload"
        multiple
      />
      <label htmlFor="file-upload">
        <IconButton component="span" sx={{ p: 1 }}>
          <CameraAltOutlined fontSize="large" />
        </IconButton>
      </label>
    </Box>
  );
}

export default FileUploader;
