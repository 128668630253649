import moment from "moment";

const getDateDisplay = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return 'Tomorrow';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    }

  };
  
  const calculateNextDueDate = (frequency, schedule) => {
    const now = new Date();
  
    console.log("Calculating next due date for task with frequency:", frequency);
  
    switch (frequency) {
      case "hourly":
        const nextHour = new Date(now.getTime() + 60 * 60 * 1000);
        console.log("Next due date for hourly task:", nextHour);
        return nextHour;
  
      case "daily":
        const nextDay = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        console.log("Next due date for daily task:", nextDay);
        return nextDay;
  
      case "weekly":
        const day = schedule.weeklyDays[0]; // Assuming only one day is selected
        const daysUntilNext = (day - now.getDay() + 7) % 7 || 7; // Calculate days until next day
        const nextWeek = new Date(now.getTime() + daysUntilNext * 24 * 60 * 60 * 1000);
        console.log("Next due date for weekly task:", nextWeek);
        return nextWeek;
  
      case "monthly":
        const dayOfMonth = schedule.monthlyDay[0]; // Assuming only one day is selected
        const nextMonth = now.getMonth() + schedule.recurrence;
        const nextYear = now.getFullYear() + Math.floor(nextMonth / 12);
        const nextMonthMod = nextMonth % 12;
        const nextDate = new Date(nextYear, nextMonthMod, dayOfMonth);
        if (nextDate.getTime() <= now.getTime()) {
          const nextMonthAfterrecurrence = nextMonthMod + schedule.recurrence;
          const nextYearAfterrecurrence = nextYear + Math.floor(nextMonthAfterrecurrence / 12);
          const nextDateAfterrecurrence = new Date(nextYearAfterrecurrence, nextMonthAfterrecurrence % 12, dayOfMonth);
          console.log("Next due date for monthly task with recurrence:", nextDateAfterrecurrence);
          return nextDateAfterrecurrence;
        }
        console.log("Next due date for monthly task:", nextDate);
        return nextDate;
  
      case "quarterly":
        const nextQuarter = now.getMonth() + 3 - ((now.getMonth() + 3) % 3) + schedule.recurrence * 3;
        const nextQuarterDate = new Date(now.getFullYear(), nextQuarter, 1);
        console.log("Next due date for quarterly task:", nextQuarterDate);
        return nextQuarterDate;
  
      case "yearly":
        const nextYearDate = new Date(now.getFullYear() + schedule.month, schedule.day);
        if (nextYearDate.getTime() <= now.getTime()) {
          const nextYearDateAfterrecurrence = new Date(now.getFullYear() + schedule.recurrence + 1, schedule.monthlyDay[0]);
          console.log("Next due date for yearly task with recurrence:", nextYearDateAfterrecurrence);
          return nextYearDateAfterrecurrence;
        }
        console.log("Next due date for yearly task:", nextYearDate);
        return nextYearDate;
  
      case "onetime":
        const oneTimeDate = new Date(schedule.onetimeDate);
        console.log("Due date for one-time task:", oneTimeDate);
        return oneTimeDate;
  
      default:
        console.log("Invalid frequency type:", frequency);
        return null;
    }
  };
  

  const formatDueDate=  (dueDate) => {
    const now = new Date();
    const diffInDays = Math.floor((dueDate.getTime() - now.getTime()) / (24 * 60 * 60 * 1000));

    if (moment(now).isSame(dueDate,"day")) {
      return "Due today";
    } else if (moment(now).add(1,"days").isSame(dueDate,"day")) {
      return "Due tomorrow";
    } else if (moment(now).add(-1,"days").isSame(dueDate,"day")) {
      return "Due yesterday";
    } else if (diffInDays < -1) {
      return `Due on ${dueDate.toLocaleDateString()}`;
    } else {
      return `Due in ${diffInDays} days (${dueDate.toLocaleDateString()})`;
    }
  }

  export { getDateDisplay,formatDueDate, calculateNextDueDate};
  