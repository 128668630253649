import React, { useState, useEffect } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { Storage } from 'aws-amplify';

function FilePreview({ fileKey, onRemove }) {
  const [thumbnailUrl, setThumbnaileUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function fetchImageUrl() {
      const url = await Storage.get("thumbnail-" + fileKey);
      setThumbnaileUrl(url);
    }

    fetchImageUrl();
  }, [fileKey]);

  const handleRemove = () => {
    onRemove();
  };

  return (
    <>
      <Box sx={{ position: 'relative', width: '64px', height: '64px' }}>
        {thumbnailUrl ? (
          <img
            src={thumbnailUrl}
            alt=""
            width="64"
            height="64"
            style={{ objectFit: 'cover' }}
            onClick={() => setOpen(true)}
          />
        ) : (
          <div>Loading...</div>
        )}
        {onRemove && <IconButton
          onClick={handleRemove}
          sx={{ position: 'absolute', top: 0, right: 0, p: 0 }}
        >
          <Cancel />
        </IconButton>}
      </Box>

      <ImageDisplay fileKey={fileKey} />
    </>
  );

  function ImageDisplay({ fileKey }) {

    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
      async function fetchImageUrl() {
        const url = await Storage.get(fileKey);
        setImageUrl(url);
      }

      fetchImageUrl();
    }, [fileKey]);

    return (
      <Modal open={Boolean(open)} onClose={() => setOpen(false)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={imageUrl}
            alt="selected"
            style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Box>
      </Modal>);
  }
}

export default FilePreview;
