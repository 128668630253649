import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDg4LNxN_vgivK9uTrL2QbuJ5zrIBcrTlU",
    authDomain: "runbook-ba39e.firebaseapp.com",
    projectId: "runbook-ba39e",
    storageBucket: "runbook-ba39e.appspot.com",
    messagingSenderId: "971374507918",
    appId: "1:971374507918:web:31eaee075f4b02fa1656df",
    measurementId: "G-ZH2KN7F6KW"
  };

let firebaseApp = null;
let messaging = null;

export function initFirebase(){
  if ('serviceWorker' in navigator) {
    console.log("Found service worker support")
    console.log("initialising firebase")
  try{
    firebaseApp= initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
    console.log("initialised firebase");
    navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('FB Service Worker registered:', registration);
          fetchToken(messaging)
        })
        .catch((error) => {
          console.error('Error registering FB Service Worker:', error);
        });

      navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Main JavaScript:', event.data);
      });

      onMessage(messaging, (payload) => {
        console.log(payload);
      });
      
      return messaging;
    } 
    catch(e){
      console.log("error initialising firebase",e);
    }
  }
  else console.log("No service worker support")

}


export const fetchToken = (messaging) => {
  return getToken(messaging, {vapidKey: 'BCWOKPSD9nHnJEg22t_8F_Icy3ReLnmy_MalEqlKLkUk16AP5v_mejqywGyZrRxXKjdpyGWaB1GBOTsAyHMqA3Q'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    
});