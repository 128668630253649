import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Modal,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EntityActivityContent from './EntityActivityContent';
import EntityRemarks from './EntityRemarks';
import { AccountCircle, Edit, Warning, WhatsApp } from '@mui/icons-material';
import { green, grey, orange, red, teal } from '@mui/material/colors';
import { isDebugEnabled } from '../utils/commonUtils';
import JsonDisplayDialog from './JsonDisplayDialog';
import { isAuthRWAOrFMOrEmployee } from '../utils/authUtils';
import CreateTaskActivity from './CreateTaskActivity';
import useTaskActions from './useTaskActions';
import { SpecialActivityType } from '../models';


const AvatarImage = styled('img')({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  objectFit: 'cover',
});

const EntityActivity = ({ activity,onClose }) => {
  const [open, setOpen] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [showJson,setShowJson] = useState(false);
  const headerSx = activity.taskactivity.missed ? { backgroundColor: red[50] } : activity.taskactivity.cancelled?{ backgroundColor: grey[300] } : { backgroundColor: green[100] };
  const [showEditor, setShowEditor] = useState(false);
  const { updateActivity } = useTaskActions();

  const handleCancelActivity = async (images, comments) => {
    await handleCreateActivity(images,comments,[],true)
  };
  const handleCreateActivity = async (images, comments,attributes,cancel=false) => {
    try {
      const result = await updateActivity(activity.taskactivity.id, images, comments,attributes,cancel);

      if (!result.error) {
        setShowEditor(false)
      } else {
        // Handle error here
        console.error('Error updating task activity:', result.error);
      }
    } catch (error) {
      // Handle error here
      console.error('Error updating task activity:', error);
    }
  };

  const handleProvideRemarks = () => {
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleSaveRemarks = () => {
    activity.missedRemarks.push({
      text: remarks,
      user: 'John Doe',
      date: new Date().toISOString(),
    });
    setRemarks('');
    setOpen(false);
  };

  const handleShare = () => {
    const shareText = `${activity.title} - ${activity.taskactivity.missed ? 'Missed' : 'Completed'}`;
    const currentURL = window.location.href;
    const activityURL = currentURL.replace(window.location.pathname, `/activity/${activity.taskactivity.id}`);
  
    // Construct the WhatsApp message with the shared text and activity URL
    const whatsappMessage = `${shareText} ${activityURL}`;
  
    // Create a temporary <a> element
    const link = document.createElement('a');
    link.href = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
  
    // Simulate a click event to open the WhatsApp share window
    link.click();
  };
  
  
  return (
    <Card>
      <CardHeader
        title={activity.taskactivity.specialType == SpecialActivityType.PATROL?"Security Patrol":activity.taskactivity.userTaskActivitiesId ? activity.taskactivity.userTaskActivitiesId : activity.system.name}
        subheader={new Date(activity.taskactivity.when).toLocaleString()}
        avatar={
          <>
            {activity.taskactivity.missed && <Warning color='error' />}
            {!activity.taskactivity.missed && !activity.user?.picture && <AccountCircle />}
            {!activity.taskactivity.missed && activity.user?.picture && <AvatarImage
              src={activity.user?.picture}
              sx={{ width: 64, height: 64, borderRadius: '50%' }}
            />}
          </>
        }
        sx={headerSx}
      />
      <CardContent>
        <EntityActivityContent activity={activity} />

        {activity.taskactivity.missed ? (
          <EntityRemarks activity={activity} />
        ) : (
          <Box>
            {/* add any other content that should be displayed */}
          </Box>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
     {isDebugEnabled() && <Button
         onClick={(e)=>{setShowJson(true)}}
          variant="outlined"
        >
          Show Json
        </Button>}
        {activity.taskactivity.missed !=true && activity.taskactivity.cancelled !=true && isAuthRWAOrFMOrEmployee() && <Button
         onClick={(e)=>{setShowEditor(true)}}
          variant="outlined"
          startIcon={<Edit/>}
        >
         Edit
        </Button>}
        <Button
          onClick={handleShare}
          startIcon={<WhatsApp />}
          variant="outlined"
          sx={{ color: '#25D366', borderColor: '#25D366' }}
        >
          Share
        </Button>

      </CardActions>
      <JsonDisplayDialog open={showJson} onClose={e=>setShowJson(false)} data={activity}></JsonDisplayDialog>
      <CreateTaskActivity
        open={showEditor}
        onClose={() =>{ setShowEditor(false);onClose && onClose()}}
        task={activity}
        onCreate={handleCreateActivity}
        onCancel={handleCancelActivity}
        taskActivity={activity.taskactivity}
      />
    </Card>
  );
};

export default EntityActivity;
