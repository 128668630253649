import React, { useState, useEffect, useReducer } from "react";
import { DataStore, SortDirection } from "@aws-amplify/datastore";
import { System, Task, TaskActivity } from "../models";
import { Typography, Box, Select, MenuItem } from "@mui/material";
import moment from "moment";
import { DateTime } from "luxon";
import ActivityCard from "./ActivityCard";

function DueTasks() {
  const [tasks, setTasks] = useState([]);
  const [filterOption, setFilterOption] = useState("dueToday");
  const [systems, setSystems] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState("All");
  const [dueOption, setDueOption] = useState("due");
  const [update, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    fetchData();

  }, [selectedSystem, filterOption, dueOption,update]);

  useEffect(() => {
    const fetchSystems = async () => {
      const systemsData = await DataStore.query(System);
      setSystems(systemsData);
    };

    fetchSystems();
    let subscription= listenForChanges()
    return () => { subscription && subscription.unsubscribe(); subscription = null };

  }, []);
 function listenForChanges(){
    const subscription = DataStore.observeQuery(TaskActivity).subscribe(snapshot=>{
      console.log("data changed")
      forceUpdate();
    })
    return subscription;
  }

  const handleSystemChange = (event) => {
    setSelectedSystem(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const handleDueChange = (event) => {
    setDueOption(event.target.value);
  };

  const fetchData = async () => {
    console.log("fetching data:");
    try {
      const currentDate = DateTime.local().startOf("day");
      const sortAsc = {
        sort: (s) => s.dueDate(SortDirection.ASCENDING),
      };
      const sortDsc = {
        sort: (s) => s.dueDate(SortDirection.ASCENDING),
      };
      const duequery = (task) => {
        return  task.or((task) => [
          task.when.eq(null),
          task.when.eq(currentDate),
        ]);
      };
      const completedquery = (task)=>task.when.ne(null);

      let fetchedTasks = [];
      let query = duequery;
      if(dueOption !== "due") query= completedquery;

      if (selectedSystem !== "All") {
        fetchedTasks = await DataStore.query(
          TaskActivity,
          (task) =>
            task.and((task) => [
              task.systemId.eq(selectedSystem),
              (dueOption === "due")?task.when.eq(null):task.when.ne(null)
            ]),
          sortAsc
        );
      } else {
        
        fetchedTasks = await DataStore.query(
          TaskActivity,
          query,
          sortAsc
        );
      }

      await processTasks(fetchedTasks, currentDate);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
 
  return (
    <Box sx={{ paddingTop: "1em" }}>
       <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          mb: 2,
          justifyContent: "center",
        }}
      >
        <Select
          value={selectedSystem}
          onChange={handleSystemChange}
          variant="outlined"
          sx={{ minWidth: "150px" }}
        >
          <MenuItem value="All">All Systems</MenuItem>
          {systems.map((system) => (
            <MenuItem
              key={system.id}
              value={system.id}
              aria-label={system.name}
            >
              {system.name}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={dueOption}
          onChange={handleDueChange}
          variant="outlined"
          sx={{ minWidth: "150px" }}
        >
          <MenuItem value="due">Due Tasks</MenuItem>
          <MenuItem value="completed">Completed Tasks</MenuItem>
        </Select>
        {dueOption === "due" && (
          <Select
            value={filterOption}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ minWidth: "150px" }}
          >
            <MenuItem value="allDueTasks">All Due Tasks</MenuItem>
            <MenuItem value="gracePeriod">In Grace Period</MenuItem>
            <MenuItem value="dueToday">Due Today</MenuItem>
            <MenuItem value="dueNext7Days">Due Next 7 Days</MenuItem>
            <MenuItem value="dueNext30Days">Due Next 30 Days</MenuItem>
          </Select>
        )}
      </Box>
      {tasks.length > 0 ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
          {tasks.map((task) => (
            <ActivityCard task={task} key={task.taskactivity.id} />
          ))}
        </Box>
      ) : (
        <Typography variant="subtitle1" align="center">
          There are no tasks for this system.
        </Typography>
      )}
    </Box>
  );

  async function processTasks(fetchedTasks, currentDate) {
    const uniqueParentTaskIDs = new Set();

    const filteredTasks = fetchedTasks.filter((task) => {
      if (!uniqueParentTaskIDs.has(task.taskTaskActivitiesId)) {
        uniqueParentTaskIDs.add(task.taskTaskActivitiesId);
        return true;
      }
      return false;
    });

    const tasksWithDueDate = await Promise.all(
      filteredTasks.map(async (taskactivity) => {
        const nextDueDate = new Date(taskactivity.dueDate);
        const system = await DataStore.query(
          System,
          taskactivity.systemId
        );
        const task = await DataStore.query(
          Task,
          taskactivity.taskTaskActivitiesId
        );
        return { ...task, nextDueDate, system, taskactivity };
      })
    );

    let filteredData = tasksWithDueDate;

    if (dueOption === "due") {
      if (filterOption === "gracePeriod") {
        filteredData = tasksWithDueDate.filter((task) => moment(task.nextDueDate).isBefore(currentDate, "day")
        );
      }
      else if (filterOption === "dueToday") {
        filteredData = tasksWithDueDate.filter((task) => moment(task.nextDueDate).isSame(currentDate, "day")
        );
      } else if (filterOption === "dueNext7Days") {
        const nextWeek = new Date(
          new Date().getTime() + 7 * 24 * 60 * 60 * 1000
        );
        filteredData = tasksWithDueDate.filter(
          (task) => task.nextDueDate <= nextWeek
        );
      } else if (filterOption === "dueNext30Days") {
        const nextMonth = new Date(
          new Date().getTime() + 30 * 24 * 60 * 60 * 1000
        );
        filteredData = tasksWithDueDate.filter(
          (task) => task.nextDueDate <= nextMonth
        );
      }
    } else if (dueOption === "completed") {
      filteredData = tasksWithDueDate.filter((task) => task.when !== null);
    }

    setTasks(filteredData);
  }
}

export default DueTasks;
