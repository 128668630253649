import { Cancel, DeleteOutlined, ImageOutlined, SyncProblemSharp } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Storage } from 'aws-amplify';
import 'photoswipe/dist/photoswipe.css'
import { useEffect, useState } from 'react';

import { Gallery, Item } from 'react-photoswipe-gallery'

export const ImageLoading = ({ onRemove }) => <Box
    // key={index}
    sx={{
        position: 'relative',
        width: 64,
        height: 64,
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    }}
>
    {onRemove && <IconButton
         onClick={onRemove}
        sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
        }}
    >
        <Cancel />
    </IconButton>}
    <ImageOutlined
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: 64,
            color: 'grey',
        }}
    />
    <CircularProgress
        size={16}
        color="primary"
        sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
        }}
    />
</Box>

export const ImageNotSynched= ({ onRemove }) => <Box
    // key={index}
    sx={{
        position: 'relative',
        width: 64,
        height: 64,
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    }}
>
    <ImageOutlined
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: 64,
            color: 'grey',
        }}
    />
    <SyncProblemSharp
        size={16}
        color="warning"
        sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
        }}
    />
</Box>



const ImageGallery = ({ imagesMetadata }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        loadImages()
    }, [])
    async function loadImages() {
        const images = []
        for (const metadata of imagesMetadata) {
            try {
                const url = await Storage.get(metadata.key);
                const thumbnail = await Storage.get("thumbnail-" + metadata.key);
                images.push({ url, thumbnail, metadata })
            } catch (error) {
                console.log(error);
            }

        }
        setImages(images)
    }


    const onBeforeOpen = (pswpInstance) => {
        const closeThis = () => {
            pswpInstance.close();
        }

        pswpInstance.on('close', () => {
            window.removeEventListener('popstate', closeThis);
        })
        window.addEventListener('popstate', closeThis);
        // add a fake state
        // Get the current state of the window history
        const currentState = window.history.state;

        // Define the new state object to be added
        const newState = { ...currentState, additionalData: Math.random() };

        // Add the new state to the window history
        window.history.pushState(newState, '');
    }
    return (
        <Gallery onBeforeOpen={onBeforeOpen}>
            {
                images.map((image, i) => (
                    <ImageItem key={i} image={image}></ImageItem>
                ))
            }
        </Gallery>
    )



    function ImageItem({ image }) {

        if (image.metadata.key == "uploading") {
            // actual image not there yet
            return <ImageNotSynched />
        }
        return <Item
            original={image.url}
            thumbnail={image.thumbnail}
            width={image.metadata.width}
            height={image.metadata.height}
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={image.thumbnail} width="64px" height="64px" sx={{ objectFit: "contain" }} />
            )}
        </Item>;
    }
}

export default ImageGallery