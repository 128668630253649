import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { TaskAttributeType } from "../models";
import { Add } from "@mui/icons-material";

function CustomAttributesTable({ customAttributes, onAddAttribute, onUpdateAttribute, onRemoveAttribute }) {
  const [lastAttributeIndex, setLastAttributeIndex] = useState(-1);

  const handleAttributeChange = (index, property, value) => {
    onUpdateAttribute(index, { ...customAttributes[index], [property]: value });
  };

  return (
    <div>
      {customAttributes.length === 0 ? (
        <p>No custom attributes added yet</p>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Mandatory</TableCell>
                <TableCell>Anomaly Detection</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customAttributes.map((attribute, index) => (
                <CustomAttributeRow
                  key={index}
                  index={index}
                  attribute={attribute}
                  onUpdate={handleAttributeChange}
                  onRemove={onRemoveAttribute}
                  isLast={index === lastAttributeIndex}
                  setLastAttributeIndex={setLastAttributeIndex}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Button onClick={onAddAttribute} startIcon={<Add></Add>}>Add Attribute</Button>
    </div>
  );
}

function CustomAttributeRow({ index, attribute, onUpdate, onRemove, isLast, setLastAttributeIndex }) {
  return (
    <TableRow>
      <TableCell>
        <TextField
          label="Name to display"
          value={attribute.name}
          onChange={(e) => onUpdate(index, "name", e.target.value)}
          fullWidth={true}
        />
      </TableCell>
      <TableCell>
        <TextField
         label="Label or acceptable values"
         helperText="In case of ListItem type provide comma seperated values"
          value={attribute.label}
          onChange={(e) => onUpdate(index, "label", e.target.value)}
          fullWidth={true}
        />
      </TableCell>
      <TableCell>
        <FormControl>
          <Select
            value={attribute.attributeType}
            onChange={(e) => onUpdate(index, "attributeType", e.target.value)}
          >
            {Object.values(TaskAttributeType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <input
          type="checkbox"
          checked={attribute.mandatory}
          onChange={(e) => onUpdate(index, "mandatory", e.target.checked)}
        />
      </TableCell>
      <TableCell>
        <TextField
         label="Regex for Anomaly Detection"
         helperText="Acceptable value . Leave blank if not required"
          value={attribute.anomalyDetection}
          onChange={(e) => onUpdate(index, "anomalyDetection", e.target.value)}
          fullWidth={true}
        />
      </TableCell>
      <TableCell>
        <Button onClick={() => onRemove(index)}>x</Button>
      </TableCell>
      {isLast && <Button onClick={() => setLastAttributeIndex(-1)} startIcon={<Add></Add>}>Add Attribute</Button>}
    </TableRow>
  );
}

export default CustomAttributesTable;
