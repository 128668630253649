import { useEffect, useState } from "react"
import fetchTodaysTasks from "../utils/taskFetcher";
import TodayTaskActivityList from "./TodayTaskActivityList";

const TodayDashboard = () =>{
    const [dailyTasks, setDailyTasks] = useState([]);
    const [periodicTasks, setPeriodicTasks] = useState([]);
    useEffect(()=>{
        fetchTodaysTasks().then(({pTask,dTask})=>{
            setDailyTasks(dTask);
            setPeriodicTasks(pTask);
        })
    },[])
    return (
        <TodayTaskActivityList taskActivities={dailyTasks}/>
    )
}

export default TodayDashboard;