import { Avatar, Box, Button, Card, CardContent, CardHeader, Snackbar, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import moment from "moment";
import { useState } from "react";
import dataFormatter from "../utils/dataFormatter";
import { Auth, DataStore } from "aws-amplify";
import { TaskActivity } from "../models";
import CreateTaskActivity from "./CreateTaskActivity";
import { formatDueDate } from "../utils/dateUtility";
import useTaskActions from "./useTaskActions";

export default function ActivityCard({ task }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const { updateActivity } = useTaskActions();

  const handlePerformTask = () => {
    setOpenDialog(true);
  };

  const updateActivityImages = async (images) => {
    const timestamp = new Date().toISOString();
    const taskActivity = await DataStore.query(TaskActivity, task.taskactivity.id);
    const updatedImages = replaceActualImage(taskActivity.images,images) //replace loading string
    const result = await DataStore.save(
      TaskActivity.copyOf(taskActivity, (updated) => {
        updated.images = updatedImages;
      })
    );
    console.log("Updated TaskActivity Images:", result);
  };

  function replaceActualImage(existingImageMetadata, newImageMetadata) {
    if(existingImageMetadata ==null || existingImageMetadata.length <=0) return newImageMetadata
    const updatedImageMetadata = [...existingImageMetadata];
  
    newImageMetadata.forEach((newImage) => {
      const existingIndex = updatedImageMetadata.findIndex(
        (existingImage) => existingImage.localFile === newImage.localFile
      );
  
      if (existingIndex !== -1) {
        updatedImageMetadata[existingIndex] = newImage;
      }
    });
  
    return updatedImageMetadata;
  }
  
  const handleCancelActivity = async (images, comments) => {
    await handleCreateActivity(images,comments,true)
  };
  const handleCreateActivity = async (images, comments,cancel=false) => {
    try {
      const result = await updateActivity(task.taskactivity.id, images, comments,cancel);

      if (!result.error) {
        setOpenDialog(false);
        setToastOpen(true);
      } else {
        // Handle error here
        console.error('Error updating task activity:', result.error);
      }
    } catch (error) {
      // Handle error here
      console.error('Error updating task activity:', error);
    }
  };

  const handleToastClose = () => {
    setToastOpen(false);
  };

  return (
    <Box>
      <Card
        key={task.taskactivity.id}
        sx={task.nextDueDate.getTime() < moment().add(1, "week") ? { borderLeft: `5px solid ${red[500]}` } : {}}
      >
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: "#1976d2" }}>{task.system.name.charAt(0)}</Avatar>}
          title={task.title}
          subheader={task.system.name}
        />
        <CardContent>
          <Typography variant="h6" color="textSecondary" component="p">
          {formatDueDate(task.nextDueDate)}
          </Typography>
          {task.taskactivity.graceDate && task.taskactivity.graceDate != task.taskactivity.dueDate && <Typography variant="h6" color="textSecondary" component="p">
            Must complete by {task.taskactivity.graceDate && moment(task.taskactivity.graceDate).format("ll")}
          </Typography>}
          <Typography variant="body2" color="textSecondary" component="p">
            {task.description}
          </Typography>
          <div style={{ marginTop: "1rem" }}>
            <p>
              <strong>Schedule:</strong> {dataFormatter.formatSchedule(task.frequency, task.schedule)}
            </p>
          </div>
        </CardContent>
        {/* <Box sx={{ display: "flex", justifyContent: "flex-end", pr: 2, pb: 2 }}>
          {pendingTask && (
            <Button size="small" onClick={handlePerformTask}>
              Perform
            </Button>
          )}
          {!pendingTask && <Typography color="green">Completed!</Typography>}
        </Box> */}
      </Card>
      <CreateTaskActivity open={openDialog} onClose={() => setOpenDialog(false)} task={task} onCreate={handleCreateActivity} onCancel={handleCancelActivity} onUpdateImage={updateActivityImages} taskActivity={task.taskactivity}/>
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message="Task recorded successfully"
        color="success"
        // You can customize the appearance and behavior of the Snackbar component as per your requirements
      />
    </Box>
  );
}
