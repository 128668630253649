import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  TextField,
} from "@mui/material";
import { daysOfWeek, weekdaysInInt, weekdaysInString } from "../utils/dataFormatter";



function TaskScheduleWeekly({ schedule, onChange }) {
  const [weeklyInterval, setWeeklyInterval] = useState(schedule.recurrence || 1);
  const [weeklyDays, setWeeklyDays] = useState( schedule.weeklyDays || []);

  useEffect(() => {
    onChange({ ...schedule, recurrence: weeklyInterval, weeklyDays:weeklyDays});
  }, [weeklyInterval, weeklyDays]);

  // const handleIntervalChange = (event) => {
  //   const interval = parseInt(event.target.value);
  //   setWeeklyInterval(interval);
  // };

  const handleDayChange = (event) => {
    const day = event.target.value;
    let days = [...weeklyDays];
    if (days.includes(day)) {
      days = days.filter((d) => d !== day);
    } else {
      days.push(day);
    }
    setWeeklyDays(days);
  };



 

  return (
    <>
      {/* <TextField
        label="Interval (weeks)"
        type="number"
        value={weeklyInterval}
        onChange={handleIntervalChange}
        inputProps={{ min: 1 }}
        sx={{ mt: 2 }}
      /> */}
      <FormControl component="fieldset" sx={{ mt: 2 }}>
        <FormLabel component="legend">Days of week</FormLabel>
        <FormGroup row>
          {daysOfWeek.map((day) => (
            <FormControlLabel
              key={day}
              control={
                <Checkbox
                  checked={weeklyDays.includes(day)}
                  value={day}
                  onChange={handleDayChange}
                />
              }
              label={day}
            />
          ))}
        </FormGroup>
      </FormControl>
    </>
  );
}

export default TaskScheduleWeekly;
