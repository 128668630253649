import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { API, Auth, DataStore } from "aws-amplify";
import { User } from "../models";
import { createUser } from '../graphql/mutations';
import DSProgressIndicator from "./DSProgressIndicator";

import { graphqlOperation } from '@aws-amplify/api-graphql';
import { getUser } from '../graphql/queries';

// ...

const fetchUserRecord = async (user) => {
  const response = await API.graphql(graphqlOperation(getUser, { id: user.username }));
  const userRecord = response.data.getUser;
  // Process the userRecord data as needed
  console.log('User Record:', userRecord);
  return userRecord;
};

function Header({ toggleDrawer }) {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    // Set the page title based on the current pathname
    const path = location.pathname;
    let title = '';

    // You can customize the page titles based on your routes
    if (path === '/status') {
      title = 'Dashboard';
    } else if (path === '/activities') {
      title = 'Activity Feed';
    } else if (path === '/managesystems') {
      title = 'Systems';
    }
    else if (path === '/todaysaction') {
      title = 'Action Center';
    }
    else if (path === '/summary') {
      title = 'Task Summary';
    }
    else if (path === '/patrol') {
      title = 'Patrol slots';
    }
    else if (path === '/routerecorder') {
      title = 'Perform Patrol';
    }
    else if (path === '/managepatrol') {
      title = 'Patrol Routes';
    }

    setPageTitle(title);
  }, [location]);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(async (user) => {
      try {
        const userRecord = await fetchUserRecord(user);
        if (!userRecord) {
          console.log("New user, creating record");
          API.graphql({
            query: createUser,
            variables: {
              input: {
                id: user.username,
                username: user.username,
                email: user.email
              }
            }
          });
        }
      } catch (error) {
        console.log("Error getting auth user", error);
      }
    }).catch(e => {
      console.log("Error getting auth user", e);
    });
  }, []);

  const handleMenuOpen = (event) => {
    toggleDrawer();
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  // Determine the environment
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <AppBar position="static"  sx={{backgroundColor:!isProduction ?"green":"#1976d2"}}> 
      <Toolbar sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
        <Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          {pageTitle} {/* Display the dynamic page title */}
        </Box>
        <Box>
          <DSProgressIndicator />
          <Button color="inherit" onClick={handleSignOut}>
            Sign Out
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
