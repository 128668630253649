import { styled } from '@mui/material/styles';

export const Arc = styled('div')(({ rag }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  clip: 'rect(0, 200px, 200px, 100px)',
  transform: 'rotate(-90deg)',
  boxShadow: 'inset 0 0 15px rgba(0, 0, 0, 0.1)',
  background: rag,
  transition: 'all 0.5s ease-out',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-5%',
    left: '100%',
    display: rag === 'Green' ? 'none' : 'block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    background: '#fafafa',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
    transition: 'all 0.5s ease-out',
  },
}));
