import { DataStore, SortDirection } from "aws-amplify";
import { DateTime } from "luxon";
import { Task, TaskActivity } from "../models";

const fetchTodaysTasks = async (selectedSystems = [], selectedDate = new Date()) => {
    try {
        // Parse the string into a Date object
        let startTime = new Date(selectedDate);

        // Set hours, minutes, seconds, and milliseconds to zero
        startTime.setUTCHours(0);
        startTime.setUTCMinutes(0);
        startTime.setUTCSeconds(0);
        startTime.setUTCMilliseconds(0);


        // Parse the string into a Date object
        let endTime = new Date(selectedDate);

        // Set hours, minutes, seconds to last 
        endTime.setUTCHours(23);
        endTime.setUTCMinutes(59);
        endTime.setUTCSeconds(59);
        endTime.setUTCMilliseconds(0);

        const currentDate = DateTime.fromJSDate(selectedDate);
        const startOfDay = startTime.toISOString();
        const sevenDaysLater = new Date(currentDate.plus({ days: 7 }).endOf("day")).toISOString();
        const endOfDay =  endTime.toISOString();

        console.log("fetching data for ", selectedDate.toISOString(), startOfDay, endOfDay, sevenDaysLater)
        const sortDueDate = {
            sort: (s) => s.dueDate(SortDirection.ASCENDING),
        };
        const sortWhen = {
            sort: (s) => s.when(SortDirection.ASCENDING),
        };
        const fetchedTasks = await DataStore.query(TaskActivity, (task) =>
            task.and(systemTask => [
                selectedSystems.length > 0 ? systemTask.or((orTask) => selectedSystems.reduce((result, id) => [...result, orTask.systemId.eq(id)], [])) : systemTask.systemId.ne(null),
                systemTask.or((t) => [
                    t.and((t1) => [t1.dueDate.eq(startOfDay), t1.graceDate.eq(startOfDay)]), // daily tasks
                    t.and((t2) => [t2.dueDate.gt(startOfDay),t2.dueDate.lt(sevenDaysLater), t2.when.eq(null)]), // due less than 7 days from now and not done
                    t.and((t3) => [t3.when.gt(startOfDay), t3.when.lt(endOfDay)]), // show today completed tasks
                   t.and((t4) => [t4.graceDate.gt(startOfDay),t4.graceDate.lt(sevenDaysLater), t4.when.eq(null)]), // grace less than 7 days from now and not done
                ])
            ])
            ,
            sortDueDate,
            sortWhen
        );

        const uniqueParentTaskIDs = new Set();

        const filteredTasks = fetchedTasks.filter((task) => {
            /* load only unique tasks*/
            if (!uniqueParentTaskIDs.has(task.taskTaskActivitiesId)) {
                uniqueParentTaskIDs.add(task.taskTaskActivitiesId);
                return true;
            }
            return false;
        });

        try {
            const dTask = [];
            const pTask = [];

            await Promise.all(
                filteredTasks.map(async (taskActivity) => {
                    const task = await DataStore.query(Task, taskActivity.taskTaskActivitiesId);
                    const taskObject = { task, taskActivity };
                    if (task) {
                        if (task.frequency === "daily") {
                            dTask.push(taskObject);
                        } else {
                            pTask.push(taskObject);
                        }
                    }
                    // some cases where task is missing 
                })
            );
            return { dTask, pTask }
        } catch (error) {
            console.error("Error fetching task objects:", error);
        }
    } catch (error) {
        console.log("Error fetching data:", error);
    }
};

export default fetchTodaysTasks;