import React, { useState, useEffect } from 'react';
import { SecurityPatrol, SecurityPatrollingStatus } from '../models';
import { DataStore } from 'aws-amplify';
import { CallMissed, CheckCircle, MoreHoriz, QrCode, Rule, UploadFile } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Typography, useTheme } from '@mui/material';
import FileUploader from './FileUploader';

export const SlotButton = ({ slot, active, handleStartRoute, isPresent, selectedDate }) => {
    const [status, setStatus] = useState(SecurityPatrollingStatus.NOT_STARTED);
    const [images, setImages] = useState([]);
    const [uploadFromMygate, setUploadFromMygate] = useState(false)
    const [securityPatrolId, setSecurityPatrolId] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [patrol, setPatrol] = useState(null)
    const theme = useTheme();

    useEffect(() => {
        loadSecurityPatrolStatus();
    }, [securityPatrolId]);

    const loadSecurityPatrolStatus = async () => {
        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
        const day = String(selectedDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        try {
            const patrols = await DataStore.query(SecurityPatrol, (p1) => p1.and(p => ([p.scheduleStartTime.eq(slot), p.date.eq(formattedDate)])));
            if (patrols.length > 0) {
                console.log('Found patrol for:', slot, patrols[0].id);
                setStatus(patrols[0].status);
                setSecurityPatrolId(patrols[0].id);
                setImages(patrols[0].images)
                setPatrol(patrols[0])
            } else {
                console.log('No patrol for:', slot);
            }
        } catch (error) {
            console.log('Error loading security patrol:', error);
        }
    };

    const saveMygatePatrol = async () => {

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const formattedTime = currentDate.toISOString().split('T')[1].slice(0, 8);
        const formattedHour = slot // use slot passed as param
        try {
            let patrolToSave = null;
            if (patrol) {
                //update
                console.log('Updating patrol for:', securityPatrolId);
                patrolToSave = SecurityPatrol.copyOf(patrol, (updated) => {
                    updated.images = images;
                    updated.actualStartTime = formattedTime;
                    updated.status = SecurityPatrollingStatus.COMPLETED_IN_MYGATE;
                    updated.onMygate = true
                })
            }
            else {
                //new 
                console.log('Saving new patrol ');
                patrolToSave = new SecurityPatrol({
                    date: formattedDate,
                    actualStartTime: formattedTime,
                    status: SecurityPatrollingStatus.COMPLETED_IN_MYGATE,
                    scheduleStartTime: formattedHour,
                    images: images,
                    onMygate: true
                });
            }

            const securityPatrol = await DataStore.save(
                patrolToSave
            );

            setSecurityPatrolId(securityPatrol.id);
            setPatrol(securityPatrol)

        } catch (error) {
            console.log('Error starting security patrol:', error);
        }
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleImageChange = (files) => {
        setImages(files);
    };

    const handleUploadMygate = () => {
        if (images.length == 0) {
            alert("Please upload screenshot")
        }
        else {
            saveMygatePatrol();
            handleDialogClose();
            setUploadFromMygate(false)
        }
    }
    const getIcon = () => {
        if (isPresent) {
            if (status === SecurityPatrollingStatus.COMPLETED || status === SecurityPatrollingStatus.COMPLETED_IN_MYGATE) return <CheckCircle color={active ? 'inherit' : 'success'} />;
            if (active) return <MoreHoriz></MoreHoriz>;
            if (status === SecurityPatrollingStatus.STARTED) return <Rule color='error'></Rule>; // started but not active anymore
            if (selectedDate.toISOString().split('T')[1].slice(0, 8) < slot) return <></>;
            else return <CallMissed color='error'></CallMissed>;
        } else {
            if (status === SecurityPatrollingStatus.COMPLETED || status === SecurityPatrollingStatus.COMPLETED_IN_MYGATE) return <CheckCircle color={active ? 'inherit' : 'success'} />;
            if (status === SecurityPatrollingStatus.STARTED) return <Rule color='error'></Rule>; // started but not active anymore
            else return <CallMissed color='error'></CallMissed>;
        }
    };
    //button should be enabled for current slot and all previous slots
    const shouldEnable = active || slot < selectedDate.toISOString().split('T')[1].slice(0, 5);
    return (
        <>
            <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                disabled={ !shouldEnable}
                sx={{
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 0, // Remove default padding
                    backgroundColor: active ? theme.palette.primary.main : 'lightgrey',
                    color: active ? theme.palette.primary.contrastText : 'darkgrey',
                    '&:hover': {
                        backgroundColor: active ? theme.palette.primary.dark : 'lightgrey',
                    },
                }}
                onClick={(e) => {
                    handleDialogOpen();
                }}
                endIcon={getIcon()}
            >
                {slot}
            </Button>

            {/* Dialog Box */}
            <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
                {!uploadFromMygate && <DialogTitle> Where to scan ?</DialogTitle>}
                {uploadFromMygate && <DialogTitle> Please upload mygate screenshot</DialogTitle>}
                <DialogContent>
                    {!uploadFromMygate && <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                onClick={() => {
                                    setUploadFromMygate(true)
                                }}
                            //startIcon={<UploadFile size="large"/>} // Adjust the fontSize as needed
                            >
                                Upload Scan <br></br>from Mygate
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={true}
                                onClick={() => {
                                    // Proceed as usual for starting the scan
                                    handleStartRoute(slot,active);
                                    handleDialogClose();
                                }}
                            //startIcon={<QrCode></QrCode>}
                            >
                                Start Scan <br></br> here
                            </Button>
                        </Grid>
                    </Grid>}
                    {uploadFromMygate && <Stack direction="column">
                        <FileUploader
                            onFileUploaded={handleImageChange}
                            existingFiles={images}
                        />
                        <Button onClick={handleUploadMygate}>Done</Button>
                    </Stack>}
                </DialogContent>
            </Dialog>
        </>
    );
};
