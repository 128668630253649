import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Bugfender } from '@bugfender/sdk';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers';




const isMobileDevice = window.matchMedia('(max-width: 768px)').matches;

if (isMobileDevice) {
  Bugfender.init({
    appKey: 'KBgPpfAA13VgI1Wpka5RDCYMfiL8lMy4',
  });
  console.log("Loading application mobile mode")
}
else console.log("Loading application desktop mode")

//  service workers section

if ('serviceWorker' in navigator && 'PeriodicSyncManager' in window) {
  navigator.serviceWorker.ready.then(function(registration) {
    return registration.sync.register('dataSync', {
      minInterval: 60 * 60 * 1000, // Minimum interval in milliseconds (e.g., 24 hours)
      networkState: 'online', // Only trigger sync when the device is online
    }); // Register a sync event with a specific tag
  }).then(function() {
    console.log('Sync event registered successfully');
  }).catch(function(error) {
    console.error('Error registering sync event:', error);
  });
}

//  service workers section end

// app is loaded and started executing
const loadingMessage = document.getElementById('loading-message');

// Hide the loading message
if (loadingMessage) {
  loadingMessage.style.display = 'none';
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
  <App />
  </LocalizationProvider>
  // </React.StrictMode>
);





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function registerWorkboxServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/workbox-sw.js')
        .then((registration) => {
          console.log('Workbox Service Worker registered:', registration);
        })
        .catch((error) => {
          console.error('Error registering work box Service Worker:', error);
        });

      navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Main JavaScript:', event.data);
      });
    });
  }
}


