/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://rcxo4p2fjfakzpby45nxciohti.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-4psgz2tlrfar5jcprzahqb7qo4",
    "aws_cognito_identity_pool_id": "ap-south-1:305b36c6-6c8e-469d-a382-03ca5b5206d5",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_PV6etzcZg",
    "aws_user_pools_web_client_id": "63lnabkk0teq9umj04ebnss7l6",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "runbookeae0c1fc2ac349e894bfa5a2602acacc125245-prod",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
