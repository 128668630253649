import React, { useState } from 'react';
import QrReader from 'react-qr-scanner';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Cameraswitch } from '@mui/icons-material';

const QRScanner = ({ onScan, expectedResult }) => {
  const [result, setResult] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [cameraFacingMode, setCameraFacingMode] = useState('environment'); // 'environment' for back camera, 'user' for front camera
  const toggleCameraFacingMode = () => {
    setCameraFacingMode((prevMode) =>
      prevMode === 'environment' ? 'user' : 'environment'
    );
  };

  const handleScan = (data) => {
    if (data) {
      setResult(data.text);

      // Compare the scanned result with the expected result
      if (data.text === expectedResult) {
        setErrorMessage(null); // Clear any previous error message
        onScan(data.text);
      } else {
        setErrorMessage('Scanned QR code does not match the expected result.');
      }
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  const handleCloseErrorMessage = () => {
    setErrorMessage(null);
  };

  return (
    <Container sx={{marginTop:"5px"}}>
      {/* Error Message Popup */}
      <Dialog open={!!errorMessage} onClose={handleCloseErrorMessage}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorMessage} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {!errorMessage && <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
        constraints={{ video: { facingMode: cameraFacingMode }, audio: false }} // Add audio: false
      />}
      {/* <p>Result: {result ? result.text : ''}</p> */}
      <Button onClick={toggleCameraFacingMode} startIcon={<Cameraswitch></Cameraswitch>} variant='outlined' fullWidth={true}>
        {cameraFacingMode === 'environment' ? 'Back' : 'Front'}
      </Button>
      
    </Container>
  );
};

export default QRScanner;
