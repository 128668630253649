import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Snackbar,
  Typography,
} from '@mui/material';
import { blue, green, grey, red } from '@mui/material/colors';
import moment from 'moment';
import CreateTaskActivity from './CreateTaskActivity';
import useTaskActions from './useTaskActions';
import { formatDueDate } from '../utils/dateUtility';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'; // Import the HourglassEmptyIcon
import CheckIcon from '@mui/icons-material/Check'; // Import the CheckIcon
import { DataStore } from '@aws-amplify/datastore';
import { System } from '../models'; // Import your System model
import dataFormatter from '../utils/dataFormatter';
import { CallMissed, Cancel, Warning } from '@mui/icons-material';
import JsonDisplayDialog from './JsonDisplayDialog';
import { isDebugEnabled } from '../utils/commonUtils';
import EntityDisplayDialog from './EntityDisplayDialog';

export default function MiniTaskActivity({ task, taskActivity, showDue }) {

  const { updateActivity } = useTaskActions();
  const [system, setSystem] = useState(''); // Initialize systemName
  const [showJson,setShowJson] = useState(false);
  const [showViewer,setShowViewer] = useState(false);
  

  useEffect(() => {
    // Fetch the system name when the component mounts
    fetchSystemName();
  }, []);

  const fetchSystemName = async () => {
    try {
      const system = await DataStore.query(System, task.systemId);
      setSystem(system); // Set the system name in state
    } catch (error) {
      console.error('Error fetching system name:', error);
    }
  };

  const [showEditor, setShowEditor] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const isTaskDone = taskActivity.when !== null && !taskActivity.cancelled && !taskActivity.missed

  let highLightDuedate = moment(taskActivity.dueDate).isBefore (moment(), "day")  && !isTaskDone
  let highlightGraceDate = moment(taskActivity.graceDate).isSame(moment(), "day") && !isTaskDone;
  if(taskActivity.graceDate == taskActivity.dueDate) highLightDuedate = highlightGraceDate; // if due == grace, we wont display gracedate, so highlight due date

  const handlePerformTask = () => {
    if(!taskActivity.missed && !taskActivity.cancelled){
      if(taskActivity.when != null) setShowViewer(true)
      else setShowEditor(true);
    }
    
  };

  const handleCancelActivity = async (images, comments) => {
    await handleCreateActivity(images,comments,[],true)
  };
  const handleCreateActivity = async (images, comments,attributes,cancel=false) => {
    try {
      const result = await updateActivity(taskActivity.id, images, comments,attributes,cancel);

      if (!result.error) {
        setShowEditor(false);
        setToastOpen(true);
      } else {
        // Handle error here
        console.error('Error updating task activity:', result.error);
      }
    } catch (error) {
      // Handle error here
      console.error('Error updating task activity:', error);
    }
  };

  const handleToastClose = () => {
    setToastOpen(false);
  };

  return (
    <Box>
      <Card
        key={taskActivity.id}
        onClick={handlePerformTask}
        style={{ cursor: 'pointer' }}
      >
        <CardHeader
          avatar={
            <Avatar
              sx={{
                bgcolor: isTaskDone ? green[500]:taskActivity.cancelled?grey[500]:taskActivity.missed?red[100]:blue[500],
                color: '#fff',
              }}
            >
              {isTaskDone ? <CheckIcon /> :taskActivity.cancelled? <Cancel></Cancel>:taskActivity.missed?<CallMissed></CallMissed>: <HourglassEmptyIcon />}
            </Avatar>
          }
          title={task.title}
          subheader={<b>{system.name}</b>}
        />
        {showDue && <CardContent >  <Typography sx={{ fontSize: ".8em", display: "flex", alignItems: "center" }} color={highLightDuedate  ? "orange" : "inherit"}>
          <span>{formatDueDate(new Date(taskActivity.dueDate))}.</span>
          {highLightDuedate  && <Warning />}
        </Typography>

          {taskActivity.graceDate && taskActivity.graceDate != taskActivity.dueDate && <Typography sx={{ fontSize: ".8em", display: "flex", alignItems: "center" }} color={highlightGraceDate && !isTaskDone ? "orange" : "inherit"}>
            Complete by {taskActivity.graceDate && moment(taskActivity.graceDate).format("ll")}.
            {highlightGraceDate  && <Warning />}
          </Typography>}
          <Typography sx={{ fontSize: ".8em" }}>{dataFormatter.formatSchedule(task.frequency, task.schedule)}.</Typography>
        </CardContent>}
          {isDebugEnabled() && <CardActions>
              <Button onClick={(e)=>{setShowJson(true);e.stopPropagation()}}>Show Json</Button>
          </CardActions>}
      </Card>
      <CreateTaskActivity
        open={showEditor}
        onClose={() => setShowEditor(false)}
        task={task}
        onCreate={handleCreateActivity}
        onCancel={handleCancelActivity}
        taskActivity={taskActivity}
      />
      <JsonDisplayDialog open={showJson} onClose={e=>setShowJson(false)} data={[taskActivity,task]}></JsonDisplayDialog>
      <EntityDisplayDialog open={showViewer} onClose={e=>setShowViewer(false)} activity={taskActivity} task={task} system={system}></EntityDisplayDialog>
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        onClose={handleToastClose}
        message="Task recorded successfully"
        color="success"
      />
    </Box>
  );
}
