import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Cancel, ThumbUp, ArrowBack, Error } from "@mui/icons-material";
import TaskAttributes from "./TaskAttributes"; // Import the TaskAttributes component
import FileUploader from "./FileUploader";
import { CustomAttribute } from "../models";

function CreateTaskActivity({
  open,
  onClose,
  onCreate,
  onCancel,
  task,
  onUpdateImage,
  taskActivity
}) {
  const [comments, setComments] = useState(taskActivity?.comments || "");
  const [images, setImages] = useState(taskActivity?.images);
  const [uploading, setUploading] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showInvalidDialog, setShowInvalidDialog] = useState(false);
  const [isValidCustom, setisValidCustom] = useState(true);
  const temp = taskActivity.attributes || task.attributes || []
  const [customAttributes, setCustomAttributes] = useState([...temp]) // Initialize custom attributes from task.attributes

  const handleFileUploadInit = (files) => {
    console.log("upload started");
    setUploading(true);
    handleImageChange(files);
  };

  const handleUploadComplete = () => {
    console.log("upload completed");
    setUploading(false);
  };

  const handleImageChange = (files) => {
    setImages(files);
    onUpdateImage && onUpdateImage(files);
  };

  const handleCancelConfirmation = () => {
    setShowCancelConfirmation(true);
  };

  const handleConfirmCancel = () => {
    setShowCancelConfirmation(false);
    onCancel(images, comments);
  };

  const handleCancel = () => {
    setShowCancelConfirmation(false);
  };
  const handleSubmit = () => {
    if (isValidCustom) onCreate(images, comments, customAttributes)
    else setShowInvalidDialog(true);
  }

  // Handle custom attributes change
  const handleCustomAttributesChange = (index, value) => {
    // customAttributes[index]=CustomAttribute.copyOf((customAttributes[index],updated=>{
    //   updated.value=value
    // })) ;
    const updatedAttributes = [...customAttributes];
    updatedAttributes[index] = { ...updatedAttributes[index] }; // this is needed since data coming from datastore is readonly
    updatedAttributes[index].value = value;
    setCustomAttributes(updatedAttributes);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} fullScreen={true}>
      <DialogTitle style={{ backgroundColor: "#1976d2", color: "#fff" }}>
        Creating record for <b>{task.title}</b>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {task.description}
        </Typography>
        &nbsp;
        <DialogContentText>Add comments & pictures</DialogContentText>
        <TextareaAutosize
          minRows={4}
          placeholder="Comments"
          style={{ width: "100%", marginBottom: "16px" }}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        {/* Render custom attributes */}
        {customAttributes.length > 0 && <TaskAttributes
          attributes={customAttributes}
          onAttributeChange={handleCustomAttributesChange}
          onValidate={setisValidCustom}
          showErrors={showInvalidDialog}
        />}
        <Divider>Pictures</Divider>
        <FileUploader
          onFileUploaded={handleImageChange}
          onUploadInit={handleFileUploadInit}
          onUploadComplete={handleUploadComplete}
          existingFiles={images}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} startIcon={<ArrowBack />} color="primary">
          Back
        </Button>
        <Button
          onClick={handleCancelConfirmation}
          disabled={uploading || taskActivity.missed}
          color="error"
          startIcon={<Cancel />}
        >
          Cancel This
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={uploading || taskActivity.missed}
          color="success"
          startIcon={<ThumbUp />}
        >
          Done
        </Button>
      </DialogActions>

      {/* Cancel Confirmation Dialog */}
      <Dialog open={showInvalidDialog}>
        {/* Customized DialogTitle */}
        <DialogTitle style={{ backgroundColor: "rgba(255, 0, 0, 0.5)", color: 'white', display: 'flex', alignItems: 'center' }} c>
          <Error style={{ marginRight: '8px' }} /> Check entered information
        </DialogTitle>
        <DialogContent>
          {/* Error icon in the content */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DialogContentText><Typography paddingTop="2em">You missed some mandatory information</Typography></DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInvalidDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      {/* Cancel Confirmation Dialog */}
      <Dialog open={showCancelConfirmation} onClose={handleCancel}>
        <DialogTitle>Cancel Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to cancel this task?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>No</Button>
          <Button onClick={handleConfirmCancel} color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog >
  );
}

export default CreateTaskActivity;
