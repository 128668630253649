/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteSystem = /* GraphQL */ `
  mutation DeleteSystem(
    $input: DeleteSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    deleteSystem(input: $input, condition: $condition) {
      id
      name
      zones {
        items {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        nextToken
        startedAt
      }
      tasks {
        items {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        nextToken
        startedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      health
      health7days
      health30days
      tasksYesterday
      tasks7days
      tasks30days
      missedTaskYesterday
      missedTask7days
      missedTask30days
      lastUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone(
    $input: DeleteZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    deleteZone(input: $input, condition: $condition) {
      id
      name
      systemId
      system {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      villas {
        items {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemZonesId
    }
  }
`;
export const deleteVilla = /* GraphQL */ `
  mutation DeleteVilla(
    $input: DeleteVillaInput!
    $condition: ModelVillaConditionInput
  ) {
    deleteVilla(input: $input, condition: $condition) {
      id
      number
      zone {
        id
        name
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        villas {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemZonesId
      }
      residents {
        items {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      zoneVillasId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      name
      picture
      phone
      email
      role
      villa {
        id
        number
        zone {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        residents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        zoneVillasId
      }
      tasks {
        items {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        nextToken
        startedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      villaResidentsId
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      title
      description
      frequency
      schedule {
        weeklyDays
        monthlyDay
        monthlyWeek
        monthlyWeekDay
        yearlyMonth
        yearlyDay
        recurrence
      }
      dueDays
      assignee {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      systemId
      system {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      images
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemTasksId
      userTasksId
    }
  }
`;
export const deleteTaskActivity = /* GraphQL */ `
  mutation DeleteTaskActivity(
    $input: DeleteTaskActivityInput!
    $condition: ModelTaskActivityConditionInput
  ) {
    deleteTaskActivity(input: $input, condition: $condition) {
      id
      task {
        id
        title
        description
        frequency
        schedule {
          weeklyDays
          monthlyDay
          monthlyWeek
          monthlyWeekDay
          yearlyMonth
          yearlyDay
          recurrence
        }
        dueDays
        assignee {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        images
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTasksId
        userTasksId
      }
      systemId
      whoDid {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      dueDate
      graceDate
      when
      comments
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      missed
      cancelled
      delayed
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      approved
      specialType
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      anomalies {
        items {
          id
          attribute
          value
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          taskActivityAnomaliesId
          taskActivityBkAnomaliesId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemTaskActivitiesId
      userTaskActivitiesId
      taskTaskActivitiesId
    }
  }
`;
export const deleteTaskActivityBk = /* GraphQL */ `
  mutation DeleteTaskActivityBk(
    $input: DeleteTaskActivityBkInput!
    $condition: ModelTaskActivityBkConditionInput
  ) {
    deleteTaskActivityBk(input: $input, condition: $condition) {
      id
      taskid
      systemId
      whoDidId
      dueDate
      graceDate
      when
      comments
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      missed
      cancelled
      delayed
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      approved
      specialType
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      anomalies {
        items {
          id
          attribute
          value
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          taskActivityAnomaliesId
          taskActivityBkAnomaliesId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTaskActivityAnomaly = /* GraphQL */ `
  mutation DeleteTaskActivityAnomaly(
    $input: DeleteTaskActivityAnomalyInput!
    $condition: ModelTaskActivityAnomalyConditionInput
  ) {
    deleteTaskActivityAnomaly(input: $input, condition: $condition) {
      id
      taskActivity {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      attribute
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      taskActivityAnomaliesId
      taskActivityBkAnomaliesId
    }
  }
`;
export const deleteTaskActivityRemark = /* GraphQL */ `
  mutation DeleteTaskActivityRemark(
    $input: DeleteTaskActivityRemarkInput!
    $condition: ModelTaskActivityRemarkConditionInput
  ) {
    deleteTaskActivityRemark(input: $input, condition: $condition) {
      id
      taskActivity {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      whoProvidedRemark {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      when
      remark
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRemarksId
      taskActivityRemarksId
      taskActivityBkRemarksId
    }
  }
`;
export const deleteSecurityPatrol = /* GraphQL */ `
  mutation DeleteSecurityPatrol(
    $input: DeleteSecurityPatrolInput!
    $condition: ModelSecurityPatrolConditionInput
  ) {
    deleteSecurityPatrol(input: $input, condition: $condition) {
      id
      date
      actualStartTime
      actualEndTime
      status
      scheduleStartTime
      routeScans {
        items {
          id
          patrolLocationId
          timeOfScan
          latitude
          longitude
          sequence
          status
          user
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          securityPatrolRouteScansId
        }
        nextToken
        startedAt
      }
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      onMygate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRouteScan = /* GraphQL */ `
  mutation DeleteRouteScan(
    $input: DeleteRouteScanInput!
    $condition: ModelRouteScanConditionInput
  ) {
    deleteRouteScan(input: $input, condition: $condition) {
      id
      patrol {
        id
        date
        actualStartTime
        actualEndTime
        status
        scheduleStartTime
        routeScans {
          nextToken
          startedAt
        }
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        onMygate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patrolLocationId
      timeOfScan
      latitude
      longitude
      sequence
      status
      user
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      securityPatrolRouteScansId
    }
  }
`;
export const deletePatrolLocation = /* GraphQL */ `
  mutation DeletePatrolLocation(
    $input: DeletePatrolLocationInput!
    $condition: ModelPatrolLocationConditionInput
  ) {
    deletePatrolLocation(input: $input, condition: $condition) {
      id
      name
      qrText
      latitude
      longitude
      sequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTaskSummary = /* GraphQL */ `
  mutation DeleteTaskSummary(
    $input: DeleteTaskSummaryInput!
    $condition: ModelTaskSummaryConditionInput
  ) {
    deleteTaskSummary(input: $input, condition: $condition) {
      id
      type
      startDate
      endDate
      createdDate
      systemSummary {
        systemID
        completed
        missed
        pending
        cancelled
      }
      activitySummary {
        taskID
        name
        completed
        missed
        pending
        cancelled
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSystem = /* GraphQL */ `
  mutation CreateSystem(
    $input: CreateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    createSystem(input: $input, condition: $condition) {
      id
      name
      zones {
        items {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        nextToken
        startedAt
      }
      tasks {
        items {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        nextToken
        startedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      health
      health7days
      health30days
      tasksYesterday
      tasks7days
      tasks30days
      missedTaskYesterday
      missedTask7days
      missedTask30days
      lastUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem(
    $input: UpdateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    updateSystem(input: $input, condition: $condition) {
      id
      name
      zones {
        items {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        nextToken
        startedAt
      }
      tasks {
        items {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        nextToken
        startedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      health
      health7days
      health30days
      tasksYesterday
      tasks7days
      tasks30days
      missedTaskYesterday
      missedTask7days
      missedTask30days
      lastUpdated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createZone = /* GraphQL */ `
  mutation CreateZone(
    $input: CreateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    createZone(input: $input, condition: $condition) {
      id
      name
      systemId
      system {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      villas {
        items {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemZonesId
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone(
    $input: UpdateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    updateZone(input: $input, condition: $condition) {
      id
      name
      systemId
      system {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      villas {
        items {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemZonesId
    }
  }
`;
export const createVilla = /* GraphQL */ `
  mutation CreateVilla(
    $input: CreateVillaInput!
    $condition: ModelVillaConditionInput
  ) {
    createVilla(input: $input, condition: $condition) {
      id
      number
      zone {
        id
        name
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        villas {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemZonesId
      }
      residents {
        items {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      zoneVillasId
    }
  }
`;
export const updateVilla = /* GraphQL */ `
  mutation UpdateVilla(
    $input: UpdateVillaInput!
    $condition: ModelVillaConditionInput
  ) {
    updateVilla(input: $input, condition: $condition) {
      id
      number
      zone {
        id
        name
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        villas {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemZonesId
      }
      residents {
        items {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      zoneVillasId
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      name
      picture
      phone
      email
      role
      villa {
        id
        number
        zone {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        residents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        zoneVillasId
      }
      tasks {
        items {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        nextToken
        startedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      villaResidentsId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      name
      picture
      phone
      email
      role
      villa {
        id
        number
        zone {
          id
          name
          systemId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemZonesId
        }
        residents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        zoneVillasId
      }
      tasks {
        items {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        nextToken
        startedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      villaResidentsId
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      title
      description
      frequency
      schedule {
        weeklyDays
        monthlyDay
        monthlyWeek
        monthlyWeekDay
        yearlyMonth
        yearlyDay
        recurrence
      }
      dueDays
      assignee {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      systemId
      system {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      images
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemTasksId
      userTasksId
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      title
      description
      frequency
      schedule {
        weeklyDays
        monthlyDay
        monthlyWeek
        monthlyWeekDay
        yearlyMonth
        yearlyDay
        recurrence
      }
      dueDays
      assignee {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      systemId
      system {
        id
        name
        zones {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        health
        health7days
        health30days
        tasksYesterday
        tasks7days
        tasks30days
        missedTaskYesterday
        missedTask7days
        missedTask30days
        lastUpdated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      taskActivities {
        items {
          id
          systemId
          dueDate
          graceDate
          when
          comments
          missed
          cancelled
          delayed
          approved
          specialType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTaskActivitiesId
          userTaskActivitiesId
          taskTaskActivitiesId
        }
        nextToken
        startedAt
      }
      images
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemTasksId
      userTasksId
    }
  }
`;
export const createTaskActivity = /* GraphQL */ `
  mutation CreateTaskActivity(
    $input: CreateTaskActivityInput!
    $condition: ModelTaskActivityConditionInput
  ) {
    createTaskActivity(input: $input, condition: $condition) {
      id
      task {
        id
        title
        description
        frequency
        schedule {
          weeklyDays
          monthlyDay
          monthlyWeek
          monthlyWeekDay
          yearlyMonth
          yearlyDay
          recurrence
        }
        dueDays
        assignee {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        images
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTasksId
        userTasksId
      }
      systemId
      whoDid {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      dueDate
      graceDate
      when
      comments
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      missed
      cancelled
      delayed
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      approved
      specialType
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      anomalies {
        items {
          id
          attribute
          value
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          taskActivityAnomaliesId
          taskActivityBkAnomaliesId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemTaskActivitiesId
      userTaskActivitiesId
      taskTaskActivitiesId
    }
  }
`;
export const updateTaskActivity = /* GraphQL */ `
  mutation UpdateTaskActivity(
    $input: UpdateTaskActivityInput!
    $condition: ModelTaskActivityConditionInput
  ) {
    updateTaskActivity(input: $input, condition: $condition) {
      id
      task {
        id
        title
        description
        frequency
        schedule {
          weeklyDays
          monthlyDay
          monthlyWeek
          monthlyWeekDay
          yearlyMonth
          yearlyDay
          recurrence
        }
        dueDays
        assignee {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        systemId
        system {
          id
          name
          health
          health7days
          health30days
          tasksYesterday
          tasks7days
          tasks30days
          missedTaskYesterday
          missedTask7days
          missedTask30days
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        images
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTasksId
        userTasksId
      }
      systemId
      whoDid {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      dueDate
      graceDate
      when
      comments
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      missed
      cancelled
      delayed
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      approved
      specialType
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      anomalies {
        items {
          id
          attribute
          value
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          taskActivityAnomaliesId
          taskActivityBkAnomaliesId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      systemTaskActivitiesId
      userTaskActivitiesId
      taskTaskActivitiesId
    }
  }
`;
export const createTaskActivityBk = /* GraphQL */ `
  mutation CreateTaskActivityBk(
    $input: CreateTaskActivityBkInput!
    $condition: ModelTaskActivityBkConditionInput
  ) {
    createTaskActivityBk(input: $input, condition: $condition) {
      id
      taskid
      systemId
      whoDidId
      dueDate
      graceDate
      when
      comments
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      missed
      cancelled
      delayed
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      approved
      specialType
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      anomalies {
        items {
          id
          attribute
          value
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          taskActivityAnomaliesId
          taskActivityBkAnomaliesId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTaskActivityBk = /* GraphQL */ `
  mutation UpdateTaskActivityBk(
    $input: UpdateTaskActivityBkInput!
    $condition: ModelTaskActivityBkConditionInput
  ) {
    updateTaskActivityBk(input: $input, condition: $condition) {
      id
      taskid
      systemId
      whoDidId
      dueDate
      graceDate
      when
      comments
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      missed
      cancelled
      delayed
      remarks {
        items {
          id
          when
          remark
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemarksId
          taskActivityRemarksId
          taskActivityBkRemarksId
        }
        nextToken
        startedAt
      }
      approved
      specialType
      attributes {
        name
        label
        attributeType
        mandatory
        value
        anomalyDetection
      }
      anomalies {
        items {
          id
          attribute
          value
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          taskActivityAnomaliesId
          taskActivityBkAnomaliesId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTaskActivityAnomaly = /* GraphQL */ `
  mutation CreateTaskActivityAnomaly(
    $input: CreateTaskActivityAnomalyInput!
    $condition: ModelTaskActivityAnomalyConditionInput
  ) {
    createTaskActivityAnomaly(input: $input, condition: $condition) {
      id
      taskActivity {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      attribute
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      taskActivityAnomaliesId
      taskActivityBkAnomaliesId
    }
  }
`;
export const updateTaskActivityAnomaly = /* GraphQL */ `
  mutation UpdateTaskActivityAnomaly(
    $input: UpdateTaskActivityAnomalyInput!
    $condition: ModelTaskActivityAnomalyConditionInput
  ) {
    updateTaskActivityAnomaly(input: $input, condition: $condition) {
      id
      taskActivity {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      attribute
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      taskActivityAnomaliesId
      taskActivityBkAnomaliesId
    }
  }
`;
export const createTaskActivityRemark = /* GraphQL */ `
  mutation CreateTaskActivityRemark(
    $input: CreateTaskActivityRemarkInput!
    $condition: ModelTaskActivityRemarkConditionInput
  ) {
    createTaskActivityRemark(input: $input, condition: $condition) {
      id
      taskActivity {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      whoProvidedRemark {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      when
      remark
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRemarksId
      taskActivityRemarksId
      taskActivityBkRemarksId
    }
  }
`;
export const updateTaskActivityRemark = /* GraphQL */ `
  mutation UpdateTaskActivityRemark(
    $input: UpdateTaskActivityRemarkInput!
    $condition: ModelTaskActivityRemarkConditionInput
  ) {
    updateTaskActivityRemark(input: $input, condition: $condition) {
      id
      taskActivity {
        id
        task {
          id
          title
          description
          frequency
          dueDays
          systemId
          images
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemTasksId
          userTasksId
        }
        systemId
        whoDid {
          id
          username
          name
          picture
          phone
          email
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          villaResidentsId
        }
        dueDate
        graceDate
        when
        comments
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        missed
        cancelled
        delayed
        remarks {
          nextToken
          startedAt
        }
        approved
        specialType
        attributes {
          name
          label
          attributeType
          mandatory
          value
          anomalyDetection
        }
        anomalies {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        systemTaskActivitiesId
        userTaskActivitiesId
        taskTaskActivitiesId
      }
      whoProvidedRemark {
        id
        username
        name
        picture
        phone
        email
        role
        villa {
          id
          number
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          zoneVillasId
        }
        tasks {
          nextToken
          startedAt
        }
        taskActivities {
          nextToken
          startedAt
        }
        remarks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        villaResidentsId
      }
      when
      remark
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRemarksId
      taskActivityRemarksId
      taskActivityBkRemarksId
    }
  }
`;
export const createSecurityPatrol = /* GraphQL */ `
  mutation CreateSecurityPatrol(
    $input: CreateSecurityPatrolInput!
    $condition: ModelSecurityPatrolConditionInput
  ) {
    createSecurityPatrol(input: $input, condition: $condition) {
      id
      date
      actualStartTime
      actualEndTime
      status
      scheduleStartTime
      routeScans {
        items {
          id
          patrolLocationId
          timeOfScan
          latitude
          longitude
          sequence
          status
          user
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          securityPatrolRouteScansId
        }
        nextToken
        startedAt
      }
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      onMygate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSecurityPatrol = /* GraphQL */ `
  mutation UpdateSecurityPatrol(
    $input: UpdateSecurityPatrolInput!
    $condition: ModelSecurityPatrolConditionInput
  ) {
    updateSecurityPatrol(input: $input, condition: $condition) {
      id
      date
      actualStartTime
      actualEndTime
      status
      scheduleStartTime
      routeScans {
        items {
          id
          patrolLocationId
          timeOfScan
          latitude
          longitude
          sequence
          status
          user
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          securityPatrolRouteScansId
        }
        nextToken
        startedAt
      }
      images {
        key
        dateTimeTaken
        height
        width
        latitude
        longitude
        localFile
      }
      onMygate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRouteScan = /* GraphQL */ `
  mutation CreateRouteScan(
    $input: CreateRouteScanInput!
    $condition: ModelRouteScanConditionInput
  ) {
    createRouteScan(input: $input, condition: $condition) {
      id
      patrol {
        id
        date
        actualStartTime
        actualEndTime
        status
        scheduleStartTime
        routeScans {
          nextToken
          startedAt
        }
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        onMygate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patrolLocationId
      timeOfScan
      latitude
      longitude
      sequence
      status
      user
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      securityPatrolRouteScansId
    }
  }
`;
export const updateRouteScan = /* GraphQL */ `
  mutation UpdateRouteScan(
    $input: UpdateRouteScanInput!
    $condition: ModelRouteScanConditionInput
  ) {
    updateRouteScan(input: $input, condition: $condition) {
      id
      patrol {
        id
        date
        actualStartTime
        actualEndTime
        status
        scheduleStartTime
        routeScans {
          nextToken
          startedAt
        }
        images {
          key
          dateTimeTaken
          height
          width
          latitude
          longitude
          localFile
        }
        onMygate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patrolLocationId
      timeOfScan
      latitude
      longitude
      sequence
      status
      user
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      securityPatrolRouteScansId
    }
  }
`;
export const createPatrolLocation = /* GraphQL */ `
  mutation CreatePatrolLocation(
    $input: CreatePatrolLocationInput!
    $condition: ModelPatrolLocationConditionInput
  ) {
    createPatrolLocation(input: $input, condition: $condition) {
      id
      name
      qrText
      latitude
      longitude
      sequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePatrolLocation = /* GraphQL */ `
  mutation UpdatePatrolLocation(
    $input: UpdatePatrolLocationInput!
    $condition: ModelPatrolLocationConditionInput
  ) {
    updatePatrolLocation(input: $input, condition: $condition) {
      id
      name
      qrText
      latitude
      longitude
      sequence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTaskSummary = /* GraphQL */ `
  mutation CreateTaskSummary(
    $input: CreateTaskSummaryInput!
    $condition: ModelTaskSummaryConditionInput
  ) {
    createTaskSummary(input: $input, condition: $condition) {
      id
      type
      startDate
      endDate
      createdDate
      systemSummary {
        systemID
        completed
        missed
        pending
        cancelled
      }
      activitySummary {
        taskID
        name
        completed
        missed
        pending
        cancelled
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTaskSummary = /* GraphQL */ `
  mutation UpdateTaskSummary(
    $input: UpdateTaskSummaryInput!
    $condition: ModelTaskSummaryConditionInput
  ) {
    updateTaskSummary(input: $input, condition: $condition) {
      id
      type
      startDate
      endDate
      createdDate
      systemSummary {
        systemID
        completed
        missed
        pending
        cancelled
      }
      activitySummary {
        taskID
        name
        completed
        missed
        pending
        cancelled
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
