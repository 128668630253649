import React, { useState, useEffect } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { Task, CustomAttribute, TaskActivity } from "../models";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Divider,
  Typography,
} from "@mui/material";
import TaskScheduler from "./TaskScheduler";
import { TextAreaField } from "@aws-amplify/ui-react";
import CustomAttributesTable from "./CustomAttributesTable";

function DefineTask({ systemId, open, handleClose, initialTask }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [task, setTask] = useState(initialTask || { frequency: "daily", schedule: {}, dueDays: 0 });
  const [customAttributes, setCustomAttributes] = useState([]);

  useEffect(() => {
    if (initialTask) {
      setTitle(initialTask.title || "");
      setDescription(initialTask.description || "");
      setTask({
        frequency: initialTask.frequency || "daily",
        schedule: initialTask.schedule || {},
        dueDays: initialTask.dueDays || 0,
      });
      setCustomAttributes(initialTask.attributes || []);
    }
  }, [initialTask]);

  const handleTaskChange = (updatedTask) => {
    setTask(updatedTask);
  };

  const addCustomAttribute = () => {
    const newAttribute = {
      name: "",
      label: "",
      attributeType: "CheckListItem",
      mandatory: false,
      anomalyDetection: null
    };
    setCustomAttributes([...customAttributes, newAttribute]);
  };

  const updateCustomAttribute = (index, updatedAttribute) => {
    const updatedAttributes = [...customAttributes];
    updatedAttributes[index] = updatedAttribute;
    setCustomAttributes(updatedAttributes);
  };

  const removeCustomAttribute = (index) => {
    const updatedAttributes = customAttributes.filter((_, i) => i !== index);
    setCustomAttributes(updatedAttributes);
  };

  async function deleteTaskActivities() {
    try {
      // Query TaskActivity items
      const itemsToDelete = await DataStore.query(TaskActivity, (taskActivity) => taskActivity.and(activity=>[
        activity.taskTaskActivitiesId.eq(initialTask.id),
        activity.when.eq(null)
      ]) );
  
      // Filter and delete items that match the conditions
      await Promise.all(itemsToDelete.map(item => DataStore.delete(item)));
  
      console.log('Deleted TaskActivities:', itemsToDelete.length);
    } catch (error) {
      console.error('Error deleting TaskActivities:', error);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!title) return;
    try {
      const customAttributeModels = customAttributes.map((attr) => {
        return new CustomAttribute({
          name: attr.name,
          label: attr.label,
          attributeType: attr.attributeType,
          mandatory: attr.mandatory,
          anomalyDetection : attr.anomalyDetection
        });
      });

      if (initialTask) {
        await deleteTaskActivities();
        const updatedTask = Task.copyOf(initialTask, (updated) => {
          updated.title = title;
          updated.description = description;
          updated.frequency = task.frequency;
          updated.schedule = task.schedule;
          updated.dueDays = task.dueDays;
          updated.attributes = customAttributeModels;
        });
        await DataStore.save(updatedTask);
      } else {
        await DataStore.save(
          new Task({
            title,
            description,
            systemId,
            frequency: task.frequency,
            schedule: task.schedule,
            dueDays: task.dueDays,
            attributes: customAttributeModels,
          })
        );
      }
      setTitle("");
      setDescription("");
      setTask({ frequency: "daily", schedule: {}, dueDays: 0 });
      setCustomAttributes([]);
      handleClose();
    } catch (error) {
      console.log("Error saving task", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle style={{ backgroundColor: "#1976d2", color: "#fff" }}>
          {initialTask ? "Edit" : "Add"} Routine
        </DialogTitle>
        &nbsp;
        <DialogContent>
          {initialTask && <Typography variant="body2" color="Orange" align="center">
            Note: Clicking "Update" will delete all upcoming schedules and create new ones.
          </Typography>}
          <br></br>
          <TextField
            autoFocus
            margin="dense"
            label="Routine Title"
            type="text"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            fullWidth
          />
          <TextAreaField
            margin="dense"
            label="Routine Description"
            type="text"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
          <TaskScheduler task={task} onChange={handleTaskChange} />
          <Divider>Custom Attributes</Divider>
          <CustomAttributesTable
            customAttributes={customAttributes}
            onAddAttribute={addCustomAttribute}
            onUpdateAttribute={updateCustomAttribute}
            onRemoveAttribute={removeCustomAttribute}
          />
          <Divider></Divider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">{initialTask ? "Update" : "Create"}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DefineTask;
