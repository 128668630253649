import { Auth } from "aws-amplify";

export const isAuthApprovedUser= async ()=>{
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userRoles = user.signInUserSession.accessToken.payload["cognito:groups"];
        if (userRoles ) {
            console.log("User groups:",userRoles)
            return true;
        }
        return false;
    } catch (error) {
        console.log("Error checking user role:", error);
        return false;
    }
}
export const checkUserRole = async (allowedRoles) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userRoles = user.signInUserSession.accessToken.payload["cognito:groups"];
        if (userRoles && userRoles.some(role => allowedRoles.includes(role))) {
            return true;
        }
        return false;
    } catch (error) {
        console.log("Error checking user role:", error);
        return false;
    }
};
export const isAuthRWAOrFMOrEmployee = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userRoles = user.signInUserSession.accessToken.payload["cognito:groups"];
        if (userRoles && userRoles.some(role => ["RWA","FM","Employee"].includes(role))) {
            return true;
        }
        return false;
    } catch (error) {
        console.log("Error checking user role:", error);
        return false;
    }
};
export const hasRWARole = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userRoles = user.signInUserSession.accessToken.payload["cognito:groups"];
        if (userRoles && userRoles.some(role => ["RWA"].includes(role))) {
            return true;
        }
        return false;
    } catch (error) {
        console.log("Error checking user role:", error);
        return false;
    }
};

export const hasAdminRole = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userRoles = user.signInUserSession.accessToken.payload["cognito:groups"];
        if (userRoles && userRoles.some(role => ["Admin"].includes(role))) {
            return true;
        }
        return false;
    } catch (error) {
        console.log("Error checking user role:", error);
        return false;
    }
};

export const hasSecurityRole = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userRoles = user.signInUserSession.accessToken.payload["cognito:groups"];
        if (userRoles && userRoles.some(role => ["Security"].includes(role))) {
            return true;
        }
        return false;
    } catch (error) {
        console.log("Error checking user role:", error);
        return false;
    }
};