import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { TaskActivity, Task, User, System } from '../models';
import { Typography, Box } from '@mui/material';
import EntityActivity from './EntityActivity';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

const TaskActivityPage = () => {
  const [activity, setActivity] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchTaskActivity = async () => {
      try {
        console.log("retrieving activity with id", id)
        const subscription = DataStore.observeQuery(TaskActivity, c => c.id.eq(id)).subscribe(async snapshot => {
          const fetchedActivity = snapshot.items[0];
          if (fetchedActivity) {
            const fetchedTask = await DataStore.query(Task, fetchedActivity.taskTaskActivitiesId);
            const user = await DataStore.query(User, fetchedActivity.userTaskActivitiesId);
            const system = await DataStore.query(System, fetchedActivity.systemId);
            const activityWithTask = {
              ...fetchedTask,
              taskactivity: fetchedActivity,
              system,
              user
            };
            setActivity(activityWithTask);

            // Set page title and description
            document.title = `${fetchedTask.title} - ${fetchedActivity.missed ? 'Missed' : 'Completed'}`;
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
              metaDescription.setAttribute('content', `${fetchedTask.title} - ${fetchedActivity.missed ? 'Missed' : 'Completed'}`);
            }
          }
          setLoading(false);
        });

        return () => {
          subscription.unsubscribe();
        };
      } catch (error) {
        console.log('Error fetching TaskActivity:', error);
        setLoading(false);
      }
    };

    fetchTaskActivity();
  }, [id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!activity) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error" display="flex" alignItems="center">
          <ErrorOutline sx={{ mr: 1 }} />
          Activity not found.
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <EntityActivity activity={activity} />
    </div>
  );
};

export default TaskActivityPage;
