import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { DataStore } from "@aws-amplify/datastore";
import { System } from "../models";

function SystemSelectionDialog({ open, onClose, selectedSystems, onSystemSelect, onClearSelections }) {
  const [systems, setSystems] = React.useState([]);

  React.useEffect(() => {
    const fetchSystems = async () => {
      try {
        const systems = await DataStore.query(System);
        setSystems(systems);
      } catch (error) {
        console.error("Error fetching systems:", error);
      }
    };

    if (open) {
      fetchSystems();
    }
  }, [open]);

  const handleClearSelections = () => {
    onClearSelections();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Systems</DialogTitle>
      <DialogContent>
        {systems.map((system) => (
          <div key={system.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedSystems.includes(system.id)}
                  onChange={() => onSystemSelect(system.id)}
                />
              }
              label={system.name}
            />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClearSelections}>Clear</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SystemSelectionDialog;
