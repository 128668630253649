import React from "react";
import { Box, Button, Card, CardContent, CardActions, Typography } from "@mui/material";

const AccessDeniedScreen = ({ handleLogout }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" align="center">
            You do not have access to this application yet. Please contact residentsofsanctuary@gmail.com
          </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <Button onClick={handleLogout}>Logout</Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default AccessDeniedScreen;
