import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Container, Card, Box, CardHeader, CardContent, Typography, Divider, CardActions, Dialog, DialogTitle, Slide, IconButton, DialogContent, StepIcon, Stack } from '@mui/material';
import { Auth, DataStore } from 'aws-amplify';
import { SecurityPatrol, RouteScan, PatrolLocation, SecurityPatrollingStatus, RouteScanStatus } from '../models';
import { ArrowBack, Check, CheckCircle, Close, HourglassBottomRounded, NotInterested, Pending, QrCode } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import QRScanner from '../components/QRScanner';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const RouteRecorder = () => {
  const location = useLocation();
  const [patrolLocations, setPatrolLocations] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [securityPatrolId, setSecurityPatrolId] = useState(null);
  const [routeScans, setRouteScans] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [expectedQR, setExpectedQR] = useState(false);
  const [status, setStatus] = useState(SecurityPatrollingStatus.NOT_STARTED);
  const [slot, setSlot] = useState(location.state?.slot || new Date().toLocaleString().split(',')[1].trim().slice(0, 2))
  const [slotDate, setSlotDate] = useState(location.state?.date ||new Date());
  const [user, setUser] = useState(null);
  const active = location.state?.active || false;
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const navigate = useNavigate();
  const handleEndRoute = () => {
    navigate(
      '/patrol'
    );
  };


  useEffect(() => {
    loadPatrolLocations();
    loadSecurityPatrol();
    Auth.currentAuthenticatedUser().then(async (user) => {
      setUser(user.username)
    }).catch(e => {
      console.log("Error getting auth user", e);
    });
  }, []);

  const loadPatrolLocations = async () => {
    try {
      const locations = await DataStore.query(PatrolLocation);
      // Reorder the array based on the 'sequence' field in ascending order
      const orderedLocations = locations.sort((a, b) => a.sequence - b.sequence);
      setPatrolLocations(orderedLocations);
    } catch (error) {
      console.log('Error loading patrol locations:', error);
    }
  };

  const loadSecurityPatrol = async () => {
    const currentDate = slotDate;
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = slot // Get hour only

    try {
      const patrols = await DataStore.query(SecurityPatrol, (p1) => p1.and(p => ([p.scheduleStartTime.eq(formattedTime), p.date.eq(formattedDate)])));
      if (patrols.length > 0) {
        console.log('Found patrol for:', formattedTime, patrols[0].id);
        setSecurityPatrolId(patrols[0].id);
        await loadRouteScans(patrols[0].id);
        setStatus(patrols[0].status)
      } else {
        console.log('No patrol for:', formattedTime);
      }
    } catch (error) {
      console.log('Error loading security patrol:', error);
    }
  };

  const loadRouteScans = async (securityPatrolId) => {
    try {
      const scans = await DataStore.query(RouteScan, (r) => r.patrol.id.eq(securityPatrolId));
      // Reorder the array based on the 'sequence' field in ascending order
      const orderedScans = scans.sort((a, b) => a.sequence - b.sequence);
      setRouteScans(orderedScans);

      // Find the index of the last completed step
      const step = scans.findIndex((scan) => scan.status === 'NO');
      setActiveStep(step);
    } catch (error) {
      console.log('Error loading route scans:', error);
    }
  };

  const handleStartPatrol = async () => {
    console.log('Starting patrol for:', securityPatrolId);
    const currentDate = slotDate;
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = currentDate.toISOString().split('T')[1].slice(0, 8);
    const formattedHour = slot // use slot passed as param
    try {
      const securityPatrol = await DataStore.save(
        new SecurityPatrol({
          date: formattedDate,
          actualStartTime: formattedTime,
          status: SecurityPatrollingStatus.STARTED,
          scheduleStartTime: formattedHour,
        })
      );

      setSecurityPatrolId(securityPatrol.id);
      await createRouteScans(securityPatrol.id);
      setStatus(SecurityPatrollingStatus.STARTED)
    } catch (error) {
      console.log('Error starting security patrol:', error);
    }
  };

  const handleCompletePatrol = async () => {
    console.log('Completing patrol for:', securityPatrolId);
    const currentDate = slotDate;
    const formattedTime = currentDate.toISOString().split('T')[1].slice(0, 8);
    try {
      const curentPatrol = await DataStore.query(SecurityPatrol, securityPatrolId)
      const updated = SecurityPatrol.copyOf(curentPatrol, (updated) => {
        updated.status = SecurityPatrollingStatus.COMPLETED;
        updated.actualEndTime = formattedTime;
      });
      await DataStore.save(updated);
      setStatus(SecurityPatrollingStatus.COMPLETED)
    } catch (error) {
      console.log('Error completing security patrol:', error);
    }
  };

  const createRouteScans = async (securityPatrolId) => {
    const currentDate = slotDate;
    const formattedTime = currentDate.toISOString().split('T')[1].slice(0, 8);

    try {
      for (const location of patrolLocations) {
        const routeScan = new RouteScan({
          patrolLocationId: location.id,
          timeOfScan: formattedTime,
          latitude: 0,
          longitude: 0,
          status: 'NO',
          patrol: { id: securityPatrolId },
          sequence: location.sequence,
          user:user
        });

        await DataStore.save(routeScan);
        console.log("Created  ", routeScan)
      }

      await loadRouteScans(securityPatrolId);
    } catch (error) {
      console.log('Error creating route scans:', error);
    }
  };

  async function handleScan() {
    if (activeStep === 0 && securityPatrolId == null) {
      await handleStartPatrol(); // Start patrol on the first step
    }
    else console.log('Resuming patrol for:', securityPatrolId);
    setExpectedQR(patrolLocations[activeStep].qrText)
    setIsDialogOpen(true)
  }
  async function skipScan() {
    if (activeStep === 0 && securityPatrolId == null) {
      await handleStartPatrol(); // Start patrol on the first step
    }
    else console.log('Resuming patrol for:', securityPatrolId);
    const confirmed = window.confirm("Are you sure you want to skip scanning for this location?");

    if (confirmed) {
      handleCompleteStep(false); // move ahead
    }
  }

  const handleCompleteStep = async (success) => {

    handleCloseDialog();//close scanner
    const currentRouteScan = routeScans[activeStep];

    const currentDate = slotDate;
    const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});


    if (currentRouteScan) {
      try {
        const updatedRouteScan = RouteScan.copyOf(currentRouteScan, (updated) => {
          updated.status = success ? 'YES' : 'NOQR';
          updated.timeOfScan = formattedTime;
          updated.user=user
        });

        await DataStore.save(updatedRouteScan);
        //now update state
        const updatedScans = [...routeScans];
        updatedScans[activeStep] = updatedRouteScan;
        setRouteScans(updatedScans);
        console.log("Saved ", updatedRouteScan)
        if (activeStep === routeScans?.length - 1) {
          handleCompletePatrol();
        }
        setActiveStep(activeStep + 1); // Update activestep
      } catch (error) {
        console.log('Error updating route scan status:', error);
      }
    }
  };

  const renderSteps = () => {

    return patrolLocations.map((location, index) => {
      const isCurrentStep = activeStep === index;
      const isStepDone = status == SecurityPatrollingStatus.COMPLETED || index < activeStep;

      return (

        <Step key={location.id} active={isCurrentStep} completed={isStepDone} >
          <StepLabel ><Typography sx={{ animation: (status != SecurityPatrollingStatus.COMPLETED && isCurrentStep) ? 'blink 1s infinite' : 'none' }}>{location.name} </Typography>
            {routeScans[index] && routeScans[index].status == RouteScanStatus.YES && <Typography variant='caption' color="gray">{routeScans[index].timeOfScan} - {routeScans[index].user}</Typography>}
          </StepLabel>
          {active && status != SecurityPatrollingStatus.COMPLETED && isCurrentStep && (
            <Stack direction="row" spacing="1em" >
              <Button variant="contained" color="primary" onClick={handleScan} startIcon={<QrCode></QrCode>}>
                Scan
              </Button>
              <Button variant="contained" color="error" onClick={skipScan} startIcon={<NotInterested></NotInterested>}>
                No QR
              </Button>
            </Stack>
          )}
        </Step>

      );
    });
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: "5px" }}>
      <Dialog fullScreen open={isDialogOpen} onClose={handleCloseDialog} TransitionComponent={Transition}>
        <DialogTitle sx={{ backgroundColor: '#1976d2', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
          Scan QR
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            sx={{ marginLeft: 'auto' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <QRScanner onScan={e => handleCompleteStep(true)} expectedResult={expectedQR}></QRScanner>
        </DialogContent>
      </Dialog>
      <Card variant='outlined'>
        <CardHeader
          title={<Typography variant="h6">
            <>
              {(status == SecurityPatrollingStatus.NOT_STARTED) && "Patrolling not started"}
              {(status == SecurityPatrollingStatus.STARTED) && "Patrolling in progress"}
              {(status == SecurityPatrollingStatus.COMPLETED) && "Patrolling completed"}
            </>
          </Typography>}
          subheader={
            <Typography variant="body2">
              {slot} - ({slotDate.toLocaleDateString()})
            </Typography>
          }
          avatar={
            <>
              {(status == SecurityPatrollingStatus.NOT_STARTED) && <HourglassBottomRounded color='warning'></HourglassBottomRounded>}
              {(status == SecurityPatrollingStatus.STARTED) && <Pending color='primary'></Pending>}
              {(status == SecurityPatrollingStatus.COMPLETED) && <Check color='success'></Check>}
            </>
          }
        />
        <Divider />
        <CardContent>
          <Box sx={{ maxWidth: 400, margin: '0 auto' }}>
            <Stepper activeStep={status == SecurityPatrollingStatus.COMPLETED ? -1 : activeStep} orientation="vertical">
              {renderSteps()}
            </Stepper>
          </Box>
        </CardContent>
        <Divider />
        <CardActions >
          <Button
            startIcon={<ArrowBack />}
            variant="outlined"
            onClick={handleEndRoute}
            fullWidth={true}
            endIcon={(status == SecurityPatrollingStatus.COMPLETED) ? <CheckCircle color='success' /> : <></>}
          >
            Back
          </Button>

        </CardActions>
      </Card>
    </Container>
  );
};

export default RouteRecorder;
