import React from 'react';
import { List, ListItem, ListItemText, Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/system'; // Import styled from @mui/system

const TodayTaskActivityList = ({ taskActivities }) => {
  return (
    <List>
      {taskActivities.map((taskActivity) => (
        <StyledListItem key={taskActivity.id} taskActivity={taskActivity}>
          <Checkbox
            checked={taskActivity.when !== null && taskActivity.missed ==null && taskActivity.cancelled ==null}
            color="primary"
          />
          <ListItemText
            primary={taskActivity.task.title}
            // secondary={taskActivity.system.name}
          />
        </StyledListItem>
      ))}
    </List>
  );
};

const StyledListItem = styled(ListItem)(({ taskActivity }) => ({
  '& .MuiTypography-root': {
    color: taskActivity.missed ? 'error.main' : taskActivity.cancelled ? 'grey.500' : 'inherit',
    textDecoration: taskActivity.cancelled ? 'line-through' : 'none',
  },
}));

const getSecondaryText = (taskActivity) => {
  if (taskActivity.missed) return 'Missed';
  if (taskActivity.cancelled) return 'Cancelled';
  return '';
};

export default TodayTaskActivityList;
