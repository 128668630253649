import EXIF from "exif-js";

const convertDMSToDD = (dmsArray) => {
    if (!dmsArray || dmsArray.length < 3) {
      return null;
    }
  
    const degrees = dmsArray[0];
    const minutes = dmsArray[1];
    const seconds = dmsArray[2];
  
    let dd = degrees + minutes / 60 + seconds / (60 * 60);
  
    if (dmsArray.length === 4 && dmsArray[3] === "S" || dmsArray[3] === "W") {
      dd = -dd;
    }
  
    return dd;
  };
  
export const getImageMetadata = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
  
      img.onload = function () {
        EXIF.getData(this, function() {
          const dateTimeTaken = EXIF.getTag(this, "DateTimeOriginal");
          
          let latitude = null;
          let longitude = null;
  
          // Try retrieving latitude and longitude from different GPS tags
          const latitudeTags = ["GPSLatitude", "GPSLatitudeRef", "GPSDestLatitude"];
          const longitudeTags = ["GPSLongitude", "GPSLongitudeRef", "GPSDestLongitude"];
  
          for (const tag of latitudeTags) {
            if (latitude === null) {
              const latitudeArr = EXIF.getTag(this, tag);
              if (latitudeArr) {
                latitude = convertDMSToDD(latitudeArr);
              }
            }
          }
  
          for (const tag of longitudeTags) {
            if (longitude === null) {
              const longitudeArr = EXIF.getTag(this, tag);
              if (longitudeArr) {
                longitude = convertDMSToDD(longitudeArr);
              }
            }
          }
  
          const metadata = {
            width: this.width,
            height: this.height,
            latitude,
            longitude,
            dateTimeTaken
          };
          
          resolve(metadata);
        });
      };
    });
  };


  export const createThumbnail = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const thumbnailWidth = 64; // Set the desired thumbnail width
          const thumbnailHeight = (image.height / image.width) * thumbnailWidth;
          canvas.width = thumbnailWidth;
          canvas.height = thumbnailHeight;
          context.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);
          canvas.toBlob((blob) => {
            resolve(blob);
          }, file.type);
        };
      };
      reader.readAsDataURL(file);
    });
  };