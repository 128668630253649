import { Typography, Box } from '@mui/material';
import { Arc } from './Arc';
import { DialContainer } from './DialContainer';
import { Needle } from './Needle';

const StatusDial = ({ value, title }) => {
  const score = value;
  let color;
  if(score ==null ) color = "#696969"
  else if (score >= 9) {
    color = '#4caf50'; // dark green
  // } else if (score >= 8) {
  //   color = '#66bb6a'; // green
  // } else if (score >= 7) {
  //   color = '#8bc34a'; // light green
  } else if (score >= 6) {
    color = '#cddc39'; // lime
  } else if (score >= 5) {
    color = '#ffeb3b'; // yellow
  } else if (score >= 4) {
    color = '#ffc107'; // amber
  } else if (score >= 3) {
    color = '#ffa726'; // orange
  // } else if (score >= 2) {
  //   color = '#ff7043'; // deep orange
  } else if (score >= 1) {
    color = '#f44336'; // red
  } else {
    color = '#d32f2f'; // dark red
  }
  // Add opacity to the color for faded effect
  color = color + '80'; // e.g., '#4caf5080' for dark green with opacity

  //const rag = (value == 10) ? "green" : (value < 5) ? "red" : "amber"
  return (
    <Box>
      {/* <Typography variant="h6" align="center">
        {title}
      </Typography> */}
      <DialContainer>
        <Arc rag={color} />
        {/* <div style={{ position: 'absolute', top: '85%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: '50%', width: '24px', height: '24px', backgroundColor: value === 'green' ? '#4caf50' : value === 'amber' ? '#ffc107' : '#f44336' }} /> */}
        <Needle score={value} title={title} color={color}/>
      </DialContainer>
    </Box>
  );
};

export default StatusDial;

