import React, { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import TaskScheduleMonthly from './TaskScheduleMonthly';
import TaskScheduleDaily from './TaskScheduleDaily';
import TaskScheduleWeekly from './TaskScheduleWeekly';
import TaskScheduleQuarterly from './TaskScheduleQuarterly';
import TaskScheduleYearly from './TaskScheduleYearly';

const TaskScheduler = ({ task, onChange }) => {
  const { frequency, schedule, dueDays } = task;
  const [selectedFrequency, setSelectedFrequency] = useState(frequency);
  const [selectedDueDays, setSelectedDueDays] = useState(dueDays||0);

  const handleFrequencyChange = (event) => {
    const newFrequency = event.target.value;
    setSelectedFrequency(newFrequency);
    onChange({ ...task, frequency: newFrequency });
  };

  const handleDueDaysChange = (event) => {
    const newDueDays = parseInt(event.target.value);
    setSelectedDueDays(newDueDays);
    onChange({ ...task, dueDays: newDueDays });
  };

  const handleScheduleChange = (newSchedule) => {
    onChange({ ...task, schedule: newSchedule });
  };

  return (
    <Box>
      <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
        <InputLabel>Task Frequency</InputLabel>
        <Select
          value={selectedFrequency}
          onChange={handleFrequencyChange}
          label="Task Frequency"
        >
          <MenuItem value="hourly">Hourly</MenuItem>
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          {/* <MenuItem value="quarterly">Quarterly</MenuItem> */}
          <MenuItem value="yearly">Yearly</MenuItem>
          {/* <MenuItem value="onetime">One-time</MenuItem> */}
        </Select>
      </FormControl>
      {selectedFrequency !== 'hourly' && (
        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          {/* <InputLabel>Grace Days</InputLabel> */}
          <TextField
            type="number"
            value={selectedDueDays}
            onChange={handleDueDaysChange}
            label="Grace Days"
            variant="outlined"
            InputProps={{ inputProps: { min: 0 } }}
          />
        </FormControl>
      )}
      {selectedFrequency === 'daily' && (
        <TaskScheduleDaily
          schedule={schedule}
          onChange={handleScheduleChange}
        />
      )}
      {selectedFrequency === 'weekly' && (
        <TaskScheduleWeekly
          schedule={schedule}
          onChange={handleScheduleChange}
        />
      )}
      {selectedFrequency === 'monthly' && (
        <TaskScheduleMonthly
          schedule={schedule}
          onChange={handleScheduleChange}
        />
      )}
      {selectedFrequency === 'quarterly' && (
        <TaskScheduleQuarterly
          schedule={schedule}
          onChange={handleScheduleChange}
        />
      )}
      {selectedFrequency === 'yearly' && (
        <TaskScheduleYearly
          schedule={schedule}
          onChange={handleScheduleChange}
        />
      )}
      {/* Add other frequency options here if needed */}
    </Box>
  );
};

export default TaskScheduler;
