import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import EntityActivity from "./EntityActivity";

function EntityDisplayDialog({ open, activity,task,system,onClose }) {
  const param={...task}
  param.taskactivity=activity
  param.system=system
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>View Activity</DialogTitle>
      <DialogContent>
        <EntityActivity activity={param} onClose={onClose}></EntityActivity>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EntityDisplayDialog;
