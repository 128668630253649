import { useState } from "react";
import {
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import TaskAvatar from "./TaskAvatar";
import { dataFormatter } from "../utils/dataFormatter";
import DefineTask from "./DefineTask";
import { DataStore } from "@aws-amplify/datastore";
import { Task } from "../models";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';

const TaskListItem = ({ task, canManage }) => {
  const { id, title, description, frequency, schedule } = task;
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await DataStore.delete(Task, id);
    setConfirmOpen(false);
  };
  const handleViewOpen = () => {
    setViewOpen(true);
  };

  const handleViewClose = () => {
    setViewOpen(false);
  };
  return (
    <>
      <ListItem alignItems="flex-start">
        <TaskAvatar name={title} frequency={frequency} sx={{ mr: 2, alignSelf: "center" }} />
        <ListItemText
          primary={title}
          secondary={dataFormatter.formatSchedule(frequency, schedule)}
          primaryTypographyProps={{ style: { paddingLeft: "0.5rem" } }}
          secondaryTypographyProps={{ style: { paddingLeft: "0.5rem" } }}
          onClick={handleViewOpen}
          sx={{ flexGrow: 1 }}
        />
        <ListItemSecondaryAction>
          {canManage && (
            <>
              <IconButton edge="end" aria-label="edit" onClick={handleOpen}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => setConfirmOpen(true)}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
     {open && <DefineTask open={open} handleClose={handleClose} initialTask={task} systemId={task.systemId} />}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Delete Task</DialogTitle>
        <DialogContent>
          <DialogContent>Are you sure you want to delete this task? Note that it will also delete all activities with it</DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={viewOpen} onClose={handleViewClose} maxWidth="sm" fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

            <p style={{ marginTop: "1rem" }}>{description}</p>
            <div style={{ marginTop: "1rem" }}>
              <p><strong>Schedule:</strong> {dataFormatter.formatSchedule(frequency, schedule)}</p>
              <p><strong>Grace days:</strong> {task.dueDays}</p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskListItem;
