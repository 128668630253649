import React from "react";
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { Business, Dashboard, Engineering, LocalPolice, Route, Summarize } from "@mui/icons-material";
import { Link } from "react-router-dom";

const DrawerComponent = ({ openDrawer, closeDrawer, isApprovedUser, isAuthRWAOrFMOrEmployee,isAdmin,isSecurity }) => {
  return (
    <Drawer anchor="left" open={openDrawer} onClose={closeDrawer}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "16px" }}>
        <HomeIcon sx={{ color: "#1976d2" }} />
        <div style={{ marginLeft: "8px", color: "#1976d2" }}>Icon sanctuary</div>
      </div>
      <Divider /> 
      <List>
      {isAuthRWAOrFMOrEmployee && (
          <>

            <ListItem button component={Link} to="/todaysaction" onClick={closeDrawer}>
              <ListItemIcon>
                <Engineering />
              </ListItemIcon>
              <ListItemText primary="Action Center" />
            </ListItem>
            {isAdmin && 
            <>
            <ListItem button component={Link} to="/summary" onClick={closeDrawer}>
              <ListItemIcon>
                <Summarize></Summarize>
              </ListItemIcon>
              <ListItemText primary="Task Summary" />
            </ListItem>
            {/* <ListItem button component={Link} to="/managepatrol" onClick={closeDrawer}>
              <ListItemIcon>
                <Route></Route>
              </ListItemIcon>
              <ListItemText primary="Manage Patrol Routes" />
            </ListItem> */}
            </>
            }
            {(isSecurity || isAdmin) && 
            <>
            {/* <ListItem button component={Link} to="/patrol" onClick={closeDrawer}>
              <ListItemIcon>
                <LocalPolice></LocalPolice>
              </ListItemIcon>
              <ListItemText primary="Patrol" />
            </ListItem> */}
            </>
            }
          </>
        )}
        {isApprovedUser && (
          <>
            {/* <ListItem button component={Link} to="/" onClick={closeDrawer}>
              <ListItemIcon>
                <Speed />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem> */}
            <ListItem button component={Link} to="/activities" onClick={closeDrawer}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Activity Feed" />
            </ListItem>
            <ListItem button component={Link} to="/status" onClick={closeDrawer}>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button component={Link} to="/managesystems" onClick={closeDrawer}>
              <ListItemIcon>
                <Business></Business>
              </ListItemIcon>
              <ListItemText primary="Systems" />
            </ListItem>
            <ListItem button component={Link} to="/settings" onClick={closeDrawer}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </>
        )}
        
      </List>
    </Drawer>
  );
};

export default DrawerComponent;
