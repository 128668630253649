import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Slide, Typography, IconButton, Paper } from '@mui/material';
import { Add as AddIcon, CallMissed, CheckCircle, Close as CloseIcon, InfoRounded, MoreHoriz, Rule } from '@mui/icons-material';
import RouteRecorder from './RouteRecorder';
import { useNavigate } from 'react-router-dom';
import { DataStore } from 'aws-amplify';
import { SecurityPatrol, SecurityPatrollingStatus } from '../models';
import DateDialog from './DateDialog';
import moment from 'moment';
import { hasRWARole } from '../utils/authUtils';
import { SlotButton } from './SlotButton';


//hard coded for now .. it can be made configurable later
const hours = [
  '01:00', '03:00', '05:00', '08:30', '11:30', '15:30', '18:30', '20:30', '23:00'
];

const PatrolHome = () => {

  const [currentHourIndex, setCurrentHourIndex] = useState(getCurrentHour());
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [dateFilterOpen, setDateFilterOpen] = useState(false)
  const [admin,isAdmin]=useState(false)
  
  useEffect(()=>{
    hasRWARole().then((perm)=>isAdmin(perm));
  },[])

  const navigate = useNavigate();
  const handleStartRoute = (slot,active) => {
    navigate(
      '/routerecorder',
      { state: { slot: slot,date : selectedDate,active :active } }
    );
  };
  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  function getCurrentHour() {
    const currentTime = getCurrentTime();

    for (let i = 0; i < hours.length; i++) {
      if (i == hours.length - 1) return i // last slot return it
      if (currentTime >= hours[i] && currentTime < hours[i + 1]) {
        return i;
      }
    }
    return -1; // could not find
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHourIndex(getCurrentHour());
      //change date
      const date = new Date();
      if (date.toLocaleDateString() != selectedDate.toLocaleDateString()) setSelectedDate(date);
    }, 60000); // 1 minute interval

    return () => {
      clearInterval(interval);
    };
  }, [selectedDate]);

  
  function HoursComponent({date}) {
    const isPresent = moment(selectedDate).isSame(moment(), "day")
    return (
      <Grid container spacing={1} padding={1}>
        {hours.map((hour, index) => (
          <Grid key={index} item xs={6} md={3} lg={2}>
            <SlotButton active={isPresent && (index === currentHourIndex)} handleStartRoute={handleStartRoute} slot={hour} isPresent={isPresent} selectedDate={selectedDate}></SlotButton>
          </Grid>
        ))}
      </Grid>
    )
  }
  return (
    <div>
      <Paper>
        <Typography variant="h6" align="center" >
          <Button onClick={() => setDateFilterOpen(true)} disabled={!admin}> {selectedDate.toLocaleDateString()}</Button>
        <HoursComponent date={selectedDate}></HoursComponent>
        </Typography>
      </Paper>

      <DateDialog open={dateFilterOpen} onClose={() => setDateFilterOpen(false)} onDateSelect={setSelectedDate} />
    </div>
  );
};

export default PatrolHome;
