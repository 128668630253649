import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { System, Task } from "../models";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";

import TaskList from "./TaskList";
import SystemAvatar from "./SystemAvatar";
import DefineTask from "./DefineTask";
import DueTasks from "./DueTasks";
import { hasRWARole } from "../utils/authUtils";

function SystemCard({ system }) {
  const [tasks, setTasks] = useState([]);
  const [open, setOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(()=>{
    const fetchUser = async () =>{
      const admin = await hasRWARole();
      setIsAdmin(admin);
    }
    fetchUser();
  },[])

  useEffect(() => {
    const subscription = DataStore.observe(Task, (t) => t.systemId.eq(system.id)).subscribe((msg) => {
      if (msg.opType === "INSERT" || msg.opType === "UPDATE" || msg.opType === "DELETE") {
        fetchTasks();
      }
    });

    const fetchTasks = async () => {
      const fetchedTasks = await DataStore.query(Task, Predicates.ALL, {
        sort: (s) => s.createdAt("desc"),
        page: 0,
        limit: 100,
      });
      setTasks(fetchedTasks.filter((t) => t.systemId === system.id));
    };
    fetchTasks();

    return () => subscription.unsubscribe();
  }, [system.id]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card >
      <CardHeader
        avatar={<SystemAvatar systemName={system.name} />}
        title={system.name}
        subheader="Routines"
      />
      <Divider />
      <CardContent>
        <TaskList tasks={tasks} canManage={isAdmin}/>
        {tasks.length === 0 && (
          <Box sx={{ py: 2 }}>
            No routines found for this system.
          </Box>
        )}
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleOpen} disabled={!isAdmin}>
          Create Routine
        </Button>
      </CardActions>
      <DefineTask open={open} handleClose={handleClose} systemId={system.id} />
    </Card>
  );
}

SystemCard.propTypes = {
  system: PropTypes.instanceOf(System).isRequired,
};

export default SystemCard;
