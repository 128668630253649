import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { TaskSchedule } from "../models";

function TaskScheduleYearly({ schedule, onChange }) {
  const [selectedMonth, setSelectedMonth] = useState(schedule?.yearlyMonth || "");
  const [selectedDay, setSelectedDay] = useState(schedule?.yearlyDay || "");


  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    onChange({ scheduleType: TaskSchedule.YEARLY, yearlyMonth:parseInt(month), yearlyDay: parseInt(selectedDay)});
  };

  const handleDayChange = (event) => {
    const day = event.target.value;
    setSelectedDay(day);
    onChange({ scheduleType: TaskSchedule.YEARLY, yearlyMonth: parseInt(selectedMonth), yearlyDay:parseInt(day)});
  };


  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">Select Month</FormLabel>
        <RadioGroup
          row
          aria-label="month"
          name="month"
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          <FormControlLabel value="1" control={<Radio />} label="January" />
          <FormControlLabel value="2" control={<Radio />} label="February" />
          <FormControlLabel value="3" control={<Radio />} label="March" />
          <FormControlLabel value="4" control={<Radio />} label="April" />
          <FormControlLabel value="5" control={<Radio />} label="May" />
          <FormControlLabel value="6" control={<Radio />} label="June" />
          <FormControlLabel value="7" control={<Radio />} label="July" />
          <FormControlLabel value="8" control={<Radio />} label="August" />
          <FormControlLabel value="9" control={<Radio />} label="September" />
          <FormControlLabel value="10" control={<Radio />} label="October" />
          <FormControlLabel value="11" control={<Radio />} label="November" />
          <FormControlLabel value="12" control={<Radio />} label="December" />
        </RadioGroup>
      </FormControl>
      <TextField
        id="day"
        label="Day"
        value={selectedDay}
        onChange={handleDayChange}
        sx={{ mt: 2 }}
      />
    </div>
  );
}

export default TaskScheduleYearly;
