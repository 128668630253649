// src/components/ManagePatrolLocations.tsx
import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fab,
} from '@mui/material';
import { DataStore } from '@aws-amplify/datastore';
import { PatrolLocation } from '../models';
import { Add, Delete, Edit } from '@mui/icons-material';

const initialLocation = {
  name: '',
  qrText: '',
  latitude: 0,
  longitude: 0,
  sequence: 0, // New field
};

const ManagePatrolLocations = () => {
  const [locations, setLocations] = useState([]);
  const [locationForm, setLocationForm] = useState(initialLocation);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteLocationId, setDeleteLocationId] = useState('');

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      const fetchedLocations = await DataStore.query(PatrolLocation);
      setLocations(fetchedLocations);
    } catch (error) {
      console.log('Error fetching locations:', error);
    }
  };

  const handleAddLocation = () => {
    setIsAdding(true);
    setLocationForm(initialLocation);
  };

  const handleEditLocation = (location) => {
    setIsEditing(true);
    setLocationForm(location);
  };

  const handleDeleteLocation = (locationId) => {
    setIsDeleting(true);
    setDeleteLocationId(locationId);
  };

  const handleSaveLocation = async () => {
    try {
      if (isAdding) {
        await DataStore.save(new PatrolLocation(locationForm));
      } else if (isEditing) {
        const existingLocation = await DataStore.query(
          PatrolLocation,
          locationForm.id
        );
        if (existingLocation) {
          const updatedLocation = PatrolLocation.copyOf(
            existingLocation,
            (updated) => {
              updated.name = locationForm.name;
              updated.qrText = locationForm.qrText;
              updated.latitude = locationForm.latitude;
              updated.longitude = locationForm.longitude;
              updated.sequence = locationForm.sequence; // Update sequence
            }
          );
          await DataStore.save(updatedLocation);
        }
      }
      setIsAdding(false);
      setIsEditing(false);
      setLocationForm(initialLocation);
      fetchLocations();
    } catch (error) {
      console.log('Error saving location:', error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const locationToDelete = await DataStore.query(
        PatrolLocation,
        deleteLocationId
      );
      if (locationToDelete) {
        await DataStore.delete(locationToDelete);
      }
      setIsDeleting(false);
      setDeleteLocationId('');
      fetchLocations();
    } catch (error) {
      console.log('Error deleting location:', error);
    }
  };

  const fabStyle = {
    position: 'fixed',
    bottom: 16,
    right: 16,
  };

  return (
    <div>
      <Fab color="primary" style={fabStyle} onClick={handleAddLocation}>
        <Add></Add>
      </Fab>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>QR Code</TableCell>
              <TableCell>Latitude</TableCell>
              <TableCell>Longitude</TableCell>
              <TableCell>Sequence</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((location) => (
              <TableRow key={location.id}>
                <TableCell>{location.name}</TableCell>
                <TableCell>{location.qrText}</TableCell>
                <TableCell>{location.latitude}</TableCell>
                <TableCell>{location.longitude}</TableCell>
                <TableCell>{location.sequence}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEditLocation(location)}
                    startIcon={<Edit></Edit>}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteLocation(location.id)}
                    startIcon={<Delete></Delete>}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={isAdding || isEditing}
        onClose={() => setIsAdding(false) || setIsEditing(false)}
      >
        <DialogTitle>{isAdding ? 'Add Location' : 'Edit Location'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={locationForm.name}
            onChange={(e) =>
              setLocationForm({ ...locationForm, name: e.target.value })
            }
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            label="QR Code"
            value={locationForm.qrText}
            onChange={(e) =>
              setLocationForm({ ...locationForm, qrText: e.target.value })
            }
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            label="Latitude"
            type="number"
            value={locationForm.latitude}
            onChange={(e) =>
              setLocationForm({
                ...locationForm,
                latitude: Number(e.target.value),
              })
            }
          />
          <TextField
            label="Longitude"
            type="number"
            value={locationForm.longitude}
            onChange={(e) =>
              setLocationForm({
                ...locationForm,
                longitude: Number(e.target.value),
              })
            }
          />
          <TextField
            label="Sequence"
            type="number"
            value={locationForm.sequence}
            onChange={(e) =>
              setLocationForm({
                ...locationForm,
                sequence: Number(e.target.value),
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveLocation} color="primary">
            Save
          </Button>
          <Button
            onClick={() => setIsAdding(false) || setIsEditing(false)}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleting} onClose={() => setIsDeleting(false)}>
        <DialogTitle>Delete Location</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this location?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
          <Button onClick={() => setIsDeleting(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagePatrolLocations;
