import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Switch, FormControlLabel, Card, CardContent, Typography } from '@mui/material';
import { setCookie, shouldRestrictDataTo45Days } from '../utils/commonUtils';
import { DataStore } from 'aws-amplify';

const StyledCard = styled(Card)({
  maxWidth: 400,
  margin: 'auto',
  marginTop: '16px',
});

const Settings = () => {
  const [retrieve45DaysData, setRetrieve45DaysData] = useState(true);

  useEffect(() => {
    const cookieValue = shouldRestrictDataTo45Days()
    if (cookieValue) {
        setRetrieve45DaysData(true);
        setCookie('retrieve45DaysDataMode', 'yes', 30);
    } else {
        setRetrieve45DaysData(false);
        DataStore.stop().then(()=>{
          DataStore.start();
        })

    }
  }, []);

  const toggleRetrieve45DaysData = () => {
    const newValue = !retrieve45DaysData;
    setRetrieve45DaysData(newValue);
    setCookie('retrieve45DaysDataMode', newValue ? 'yes' : 'no', 30);
  };


  return (
    <div>
      <StyledCard>
        <CardContent>
          <Typography variant="h6" gutterBottom>Retrieve only last 45 days data</Typography>
          <FormControlLabel
            control={<Switch checked={retrieve45DaysData} onChange={toggleRetrieve45DaysData} />}
            label={retrieve45DaysData ? 'Enabled' : 'Disabled'}
          />
        </CardContent>
      </StyledCard>
    </div>
  );
};

export default Settings;
