// useTaskActions.js
import { useState } from "react";
import { Auth, DataStore } from "aws-amplify";
import { TaskActivity, TaskActivityAnomaly } from "../models";

const useTaskActions = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateActivity = async (taskId, images, comments, attributes, cancelled = false) => {
    try {
      setLoading(true);
      setError(null);

      const timestamp = new Date().toISOString();
      const taskActivity = await DataStore.query(TaskActivity, taskId);
      const user = await Auth.currentAuthenticatedUser();

      const result = await DataStore.save(
        TaskActivity.copyOf(taskActivity, (updated) => {
          updated.when = timestamp;
          updated.comments = comments;
          updated.images = images;
          updated.userTaskActivitiesId = user.username;
          updated.cancelled = cancelled
          updated.attributes = attributes
        })
      );

      if (attributes) {
        /* save task anomalies */
        const anomalies = attributes.map((attribute) => {
          if (attribute.anomalyDetection && !testRegex(attribute.anomalyDetection, attribute.value)) {
            return attribute
          }
        }).filter(value => value !== undefined);


        for (const anomaly of anomalies) {
          await DataStore.save(
            new TaskActivityAnomaly({
              taskActivity,
              attribute: anomaly.name,
              value: anomaly.value
            })
          );
        }
      }
      setLoading(false);

      return result;
    } catch (error) {
      setLoading(false);
      setError(error);
      return { error };
    }
  };

  return {
    updateActivity,
    loading,
    error,
  };
};

//returns true of expression matches else false
function testRegex(expr, value) {
  // Create a regular expression object from the string
  const regex = new RegExp(expr);
  return regex.test(value);
}

export default useTaskActions;
