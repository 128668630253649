import { Card, CardHeader, CardContent, CardActions, Typography, Avatar, Divider, Button } from '@mui/material';
import StatusDial from './StatusDial';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { TimeRange } from './enums';
import { stringToHslColor } from '../utils/colors';
import TaskSummaryComponent from '../components/TaskSummaryComponent';

function StatusCard(props) {
  const { name, health, health7days, health30days, lastUpdated, tasksYesterday, tasks7days, tasks30days, missedTaskYesterday, missedTask7days, missedTask30days } = props.system;
  const { taskSummary } = props
  const range = props.timeRange || TimeRange.YESTERDAY;
  const navigate = useNavigate();

  const avatarColor = stringToHslColor(name)
  //const avatarColor = "#" + Math.floor(Math.random() * 16777215).toString(16); // generate random color based on title
  const avatarText = name.charAt(0); // get first letter of title for avatar

  const renderedHealth = (range == TimeRange.YESTERDAY) ? health : (range == TimeRange.LAST_7_DAYS) ? health7days : health30days || 0
  const renderedTotal = (range == TimeRange.YESTERDAY) ? tasksYesterday : (range == TimeRange.LAST_7_DAYS) ? tasks7days : tasks30days || 0
  const renderedMissed = (range == TimeRange.YESTERDAY) ? missedTaskYesterday : (range == TimeRange.LAST_7_DAYS) ? missedTask7days : missedTask30days || 0
  const handleViewDetails = () => {
    navigate(
      '/activities',
      { state: { system: props.system.id } }
    );
  };

  return (
    <Card sx={{ minWidth: 275, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: avatarColor }}>{avatarText}</Avatar>}
        title={<Typography variant="h6">{name}</Typography>}
        subheader={
          <Typography variant="body2">
            {lastUpdated
              ? `Updated: ${formatDistanceToNow(new Date(lastUpdated))} ago`
              : 'No activity yet'}
          </Typography>
        }
      />
      <Divider />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {props.view == "Summary" && <> <StatusDial value={renderedHealth} title={name} />
          {renderedTotal > 0 && <Typography variant="body2" component="p" sx={{ marginTop: '1rem', textAlign: 'center' }}>
            {renderedMissed <= 0 ? `All ${renderedTotal} task(s) completed` : `${renderedMissed} tasks missed out of ${renderedTotal}`}
          </Typography>}
          {renderedTotal <= 0 && <Typography variant="body2" component="p" sx={{ marginTop: '1rem', textAlign: 'center' }}>
            No Tasks found
          </Typography>} </>}
        {props.view == "Details" && <TaskSummaryComponent timeRange={range} 
          taskSummary ={taskSummary}
          systemID={props.system.id}
        ></TaskSummaryComponent>}
      </CardContent>
      <CardActions sx={{ justifyContent: 'center' }}>
        <Button size="small" onClick={handleViewDetails}>View activity feed</Button>
      </CardActions>
    </Card>
  );
}

export default StatusCard;
