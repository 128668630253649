export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }


 export const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  };
export const isDebugEnabled =()=>{
// Check if the "debug" cookie is set to true
  const debugCookie = getCookie('debug');
  return debugCookie =="true"
}

export const shouldRestrictDataTo45Days = () =>{
  const cookieValue = getCookie('retrieve45DaysDataMode');
    if (cookieValue == undefined || cookieValue === 'yes') return true
    else return false
} 