export const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

// function takes an array of days in integers from 0 and return their respective day
export function weekdaysInInt(weeklyDaysArray){
  return weeklyDaysArray.map(day => daysOfWeek.indexOf(day));
}

// function takes an array of days in string and return their respective day
export function weekdaysInString(weeklyDaysArray){
  return weeklyDaysArray.map(day => daysOfWeek[day]);
}

export const dataFormatter = {
  formatSchedule: (frequency, schedule) => {
    switch (frequency) {
      case "hourly":
        return "Every hour";
      case "daily":
        return "Every day";
      case "weekly":
        if (!schedule.weeklyDays || schedule.weeklyDays.length === 0) {
          return `Every ${schedule.recurrence} week(s)`;
        }
        const weeklyDays = schedule.weeklyDays;
        return `Every ${schedule.recurrence} week(s) `+ weeklyDays.join(" and ");
      case "monthly":
        if (!schedule.monthlyDay || schedule.monthlyDay.length === 0) {
          return `Every ${schedule.recurrence} month(s)`;
        }
        const monthlyDayCopy = [...schedule.monthlyDay].sort((a, b) => a - b);
        return `Every ${schedule.recurrence} month(s) on the ${ordinalSuffix(monthlyDayCopy)}`;
     
      case "quarterly":
        return "Every quarter";
      case "yearly":
        return "Every year";
      case "onetime":
        return "One time only";
      default:
        return "";
    }
  },
};

function ordinalSuffix(dayList) {
  const suffixes = ["th", "st", "nd", "rd"];
  return dayList
    .map((day) => {
      const j = day % 10;
      const k = day % 100;
      if (j === 1 && k !== 11) {
        return day + suffixes[1];
      }
      if (j === 2 && k !== 12) {
        return day + suffixes[2];
      }
      if (j === 3 && k !== 13) {
        return day + suffixes[3];
      }
      return day + suffixes[0];
    })
    .join(", ");
}

export default dataFormatter;
