import React, { useState } from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { DateTime } from "luxon";

function TaskScheduleQuarterly({ handleRecurrenceChange }) {
  const [selectedMonths, setSelectedMonths] = useState([]);

  const handleMonthChange = (event) => {
    const selectedMonth = parseInt(event.target.value);
    if (event.target.checked) {
      setSelectedMonths([...selectedMonths, selectedMonth]);
    } else {
      setSelectedMonths(selectedMonths.filter((month) => month !== selectedMonth));
    }
  };

  const generateMonths = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      const month = DateTime.fromObject({ month: i }).toFormat("MMMM");
      months.push(
        <FormControlLabel
          key={month}
          control={<Checkbox value={i} />}
          label={month}
          onChange={handleMonthChange}
        />
      );
    }
    return months;
  };

  return (
    <div>
      <Typography variant="subtitle1">Recurs quarterly</Typography>
      <Typography variant="body1" color="text.secondary">
        This task will recur every 3 months.
      </Typography>
      <input type="hidden" name="recurrence" value="quarterly" onChange={handleRecurrenceChange} />
      <Box sx={{ mt: 2 }}>
        <FormControl component="fieldset">
          <FormGroup row>{generateMonths()}</FormGroup>
        </FormControl>
      </Box>
    </div>
  );
}

export default TaskScheduleQuarterly;
