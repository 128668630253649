import React from "react";
import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";

const SplashScreen = (fullscreen) => {
  const messages = [
    "Preparing the awesomeness...",
    "Loading magic...",
    "Unlocking the secrets...",
    "Finding the perfect balance...",
    "Brewing a fresh cup of data...",
    "Exploring the unknown...",
  ];

  const randomMessage = messages[Math.floor(Math.random() * messages.length)];

  const SplashContent = () => { return (<Card>
    <CardContent>
      <Typography variant="h5" component="div" align="center" mb={2}>
        {randomMessage}
      </Typography>
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    </CardContent>
  </Card>)} ;

  if (fullscreen==true)
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      backgroundColor="#f5f5f5"
      width="100%"
    >
    <SplashContent/>
    </Box>
  );
else
  return (
      <SplashContent/>
  );
};

export default SplashScreen;
