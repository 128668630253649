import React from "react";
import { Avatar } from "@mui/material";
import { stringToHslColor } from "../utils/colors";

function SystemAvatar({ systemName, ...props }) {
  const letter = systemName.charAt(0).toUpperCase();
  const color = stringToHslColor(systemName);

  return (
    <Avatar sx={{ bgcolor: color }} {...props}>
      {letter}
    </Avatar>
  );
}

export default SystemAvatar;
