import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

function JsonDisplayDialog({ open, data, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>JSON Data Display</DialogTitle>
      <DialogContent>
        <pre>
          <code>
            {JSON.stringify(data, null, 2)}
          </code>
        </pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JsonDisplayDialog;
