import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth, DataStore, Hub, Predicates, syncExpression } from 'aws-amplify';
import awsconfig from './aws-exports';
import AccessDeniedScreen from './components/AccessDeniedScreen';
import DrawerComponent from './components/DrawerComponent';
import Header from './components/Header';
import EntityActivities from './pages/EntityActivities';
import StatusPage from './pages/StatusPage';
import ManageSystems from './pages/ManageSystems';
import RecordActivities from './pages/RecordActivities';
import Home from './pages/Home';
import UpcomingTaskSummary from './pages/UpcomingTaskSummary';
import { hasAdminRole, hasSecurityRole, isAuthApprovedUser, isAuthRWAOrFMOrEmployee } from './utils/authUtils';
import SplashScreen from './components/SplashScreen';
import "@aws-amplify/ui-react/styles.css";
import TaskActivityPage from './pages/TaskActivityPage';
import QRScanner from './components/QRScanner';
import ScanController from './components/ScanController';
import ManagePatrolLocations from './pages/ManagePatrolLocations';
import PatrolHome from './pages/PatrolHome';
import {initFirebase} from './firebase'
import RouteRecorder from './pages/RouteRecorder';
import TodaysAction from './pages/TodaysAction';
import { RouteScan, TaskActivity, TaskActivityBk, TaskSummary } from './models';
import Settings from './pages/Settings';
import { shouldRestrictDataTo45Days } from './utils/commonUtils';

Amplify.configure(awsconfig);

const get45DaysBeforeDateISOString = () => {
  const currentDate = new Date();
  const fortyFiveDaysBeforeDate = new Date(currentDate.getTime() - (45 * 24 * 60 * 60 * 1000));
  return fortyFiveDaysBeforeDate.toISOString();
};
const get15DaysDateISOString = () => {
  const currentDate = new Date();
  const fifteenDaysAfterDate = new Date(currentDate.getTime() + (15 * 24 * 60 * 60 * 1000));
  return fifteenDaysAfterDate.toISOString();
};

// to build the syc expression based on preferences
function getSycExpressionforTaskActivity(){
  if (shouldRestrictDataTo45Days()){
    console.log("restricting TaskActivity for 45 days",get45DaysBeforeDateISOString(),get15DaysDateISOString())
    return syncExpression(TaskActivity, t =>t.or(s=>[
      s.dueDate.between(get45DaysBeforeDateISOString(),get15DaysDateISOString()),
      s.graceDate.between(get45DaysBeforeDateISOString(),get15DaysDateISOString()),
    ]))
  }
  else {
    console.log("Loading all task activities without restriction")
    return syncExpression(TaskActivity, Predicates.ALL)
  }
}

// to build the syc expression based on preferences
function getSycExpressionforTaskSummary(){
  // if (shouldRestrictDataTo45Days()){
  //   console.log("restricting TaskActivitySummary for 45 days",get45DaysBeforeDateISOString())
    return syncExpression(TaskSummary, t =>t.or(s=>[
      s.startDate.between(get45DaysBeforeDateISOString(),get15DaysDateISOString())
    ]))
  // }
  // else {
  //   console.log("Loading all task summary without restriction")
  //   return syncExpression(TaskSummary, Predicates.ALL)
  // }
}

DataStore.configure({
  syncExpressions : [
    getSycExpressionforTaskActivity(),
    getSycExpressionforTaskSummary(),
    syncExpression(RouteScan, t=>t.id.eq("")),
    syncExpression(TaskActivityBk, t=>t.id.eq("")),
    
  ]
})

function App() {
   // Determine the environment
   const isProduction = process.env.NODE_ENV === 'production';
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isApprovedUser, setIsApprovedUser] = useState(false);
  const [authRWAOrFMOrEmployee, setAuthRWAOrFMOrEmployee] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSecurity, setIsSecurity] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {

    const fetchUserApprovalStatus = async () => {
      try {
        const approved = await isAuthApprovedUser();
        setIsApprovedUser(approved);
        const rws = await isAuthRWAOrFMOrEmployee();
        setAuthRWAOrFMOrEmployee(rws);
        const admin = await hasAdminRole()
        setIsAdmin(admin)
        setIsSecurity(await hasSecurityRole());
        //ready(()=>{
        setIsLoading(false);
        //})
        
      } catch (error) {
        console.error('Error fetching user approval status:', error);
      }
    };

    //if(!isProduction)
    initMessaging();
    fetchUserApprovalStatus();

    return () =>{
      // do we need this? what happens to offline
      if(!isProduction){
        navigator.serviceWorker.getRegistrations().then(registrations => {
          registrations.forEach(registration => {
            registration.unregister();
          });
        });
      }
    }
  }, []);

  function initMessaging(){
    console.log('Asking for notification permission');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        //registerFirebaseServiceWorker();
        // Register the Firebase Messaging service worker
        initFirebase();
      } else {
        console.log('Notification permission denied or blocked.');
      }
    });
  }
  const handleLogout = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const ready = (callback) => {
    console.log("App will not load until synch completes")
    Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "syncQueriesReady") {
        console.log(`Data synched from cloud.. app is ready to render`);
        callback()
      }
    });
  };

  if (isLoading) {
    return (
      <SplashScreen ready={ready} fullscreen={true}>

      </SplashScreen>
    );
  }

  if (!isApprovedUser) {
    return (
      <AccessDeniedScreen
        message="You do not have access to this application yet. Please contact residentsofsanctuary@gmail.com"
        handleLogout={handleLogout}
      />
    );
  }

  return (
    <Router>
      <Header toggleDrawer={toggleDrawer} />
      <DrawerComponent openDrawer={openDrawer} closeDrawer={closeDrawer} isApprovedUser={isApprovedUser} isAuthRWAOrFMOrEmployee={authRWAOrFMOrEmployee} isAdmin={isAdmin} isSecurity={isSecurity}/>
      <Routes>
        {isApprovedUser && (
          <>
            <Route path="/" element={<EntityActivities />} />
            <Route path="/activities" element={<EntityActivities />} />
            <Route path="/status" element={<StatusPage />} />
            <Route path="/test" element={<Home />} />

            <Route path="/managesystems" element={<ManageSystems />} />
            <Route path="/recordactivities" element={<RecordActivities />} />
            <Route path="/todaysaction" element={<TodaysAction />} />
            <Route path="/settings" element={<Settings />} />
            {/* Other routes */}
            <Route path="/activity/:id" element={<TaskActivityPage />} />
          </>)
        }
        {isAdmin && (
          <>
            <Route path="/summary" element={<UpcomingTaskSummary />} />
            <Route path="/qr" element={<ScanController/>}/>
            <Route path="/managepatrol" element={<ManagePatrolLocations/>}/>
           
          </>
        )}
        {(isAdmin || isSecurity) && (
          <>
            <Route path="/patrol" element={<PatrolHome/>}/>
            <Route path="/routerecorder" element={<RouteRecorder/>}/>
          </>
        )}
      </Routes>
    </Router>
  );
}

export default withAuthenticator(App);
