import { Avatar } from "@mui/material";
import { stringToHslColor } from "../utils/colors";

const TaskAvatar = ({ name, frequency }) => {
  const letter = frequency?frequency.charAt(0).toUpperCase():"I";
  const color = stringToHslColor(name);
  const bgcolor = "rgb(0,0,0,0)";
  const border = `2px solid ${color}`;
  return (
    <Avatar sx={{ bgcolor: bgcolor, color: color, border: border }}>
      <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>{letter}</span>
    </Avatar>
  );
};

export default TaskAvatar;
