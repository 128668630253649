import { styled } from '@mui/material/styles';

export const DialContainer = styled('div')({
    position: 'relative',
    width: 200,
    height: 200,
    borderRadius: '50%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
