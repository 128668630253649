import React, { useState, useEffect, useRef, useReducer } from 'react';
import {
  Box,
  Container,
  Select,
  MenuItem,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import EntityActivity from './EntityActivity';
import { DataStore, SortDirection } from 'aws-amplify';
import { System, Task, TaskActivity, User } from '../models';
import { useLocation } from 'react-router-dom';
import SplashScreen from '../components/SplashScreen';

const PAGE_SIZE = 20;
 
const EntityActivities = () => {
  const location = useLocation();
  const [skipLoading, setSkipLoading] = useState(location.state != null);
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    system: 'All Systems',
    task: 'All Tasks',
    filter: 'Show All',
  });
  const [systemNames, setSystemNames] = useState([]);
  const [taskNames, setTaskNames] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef(null);
  const [update, forceUpdate] = useReducer(x => x + 1, 0);


  useEffect(() => {
    fetchSystemNames().then(() => {
      setSkipLoading(false);
      if (location.state) {
        setSelectedOptions((prevOptions) => ({
          ...prevOptions,
          system: location.state.system,
        }));
      }
    });
    let subscription= listenForChanges()
    return () => { subscription && subscription.unsubscribe(); subscription = null };
  }, []);

  //reload for selected options
  useEffect(() => {
    // Clear activities when selectedOptions change
    setActivities([]);
    setPage(1);
    setHasMore(true);
    fetchActivities();
    fetchTaskNames(selectedOptions.system);
  }, [selectedOptions,update]); 

  function listenForChanges(){
    const subscription = DataStore.observeQuery(TaskActivity).subscribe(snapshot=>{
      console.log("data changed")
      forceUpdate();
    })
    return subscription;
  }

  //reload for page
  useEffect(() => {
    fetchActivities();
  }, [ page]); 

  const fetchActivities = async () => {
    if (skipLoading) {
      console.log('Skipping loading ' + selectedOptions.system);
      return;
    }

    setLoading(true);

    const querySettings = {
      sort: (s) => s.when(SortDirection.DESCENDING),
      page: page - 1,
      limit: PAGE_SIZE,
    };

    const queryCondition = (task) => {
      const conditions = [task.when.ne(null)];
      if (selectedOptions.system !== 'All Systems') {
        conditions.push(task.systemId.eq(selectedOptions.system));
      }
      if (selectedOptions.task !== 'All Tasks') {
        conditions.push(task.taskTaskActivitiesId.eq(selectedOptions.task));
      }
      if (selectedOptions.filter === 'Show Missed') {
        conditions.push(task.missed.ne(null));
      } else if (selectedOptions.filter === 'Show Activities') {
        conditions.push(task.missed.eq(null));
      }
      return task.and(task => conditions);
    };

    const tasks = await DataStore.query(TaskActivity, queryCondition, querySettings);
    processActivitiesFromDB(tasks);
  };

  async function processActivitiesFromDB(fetchedActivities) {
    const newActivities = await Promise.all(
      fetchedActivities.map(async (taskactivity) => {
        const system = await DataStore.query(System, taskactivity.systemId);
        const task = await DataStore.query(Task, taskactivity.taskTaskActivitiesId);
        const user = await DataStore.query(User, taskactivity.userTaskActivitiesId);
        return { ...task, system, taskactivity, user };
      })
    );
    
    if (newActivities.length === 0) {
      setHasMore(false);
    }
    setActivities((prevActivities) => {
      // Create a set to track unique taskactivity.id values
      const uniqueTaskActivityIds = new Set(prevActivities.map((activity) => activity.taskactivity.id));
      
      // Filter out newActivities that have taskactivity.id already in the set
      const filteredNewActivities = newActivities.filter((activity) => {
        const taskId = activity.taskactivity.id;
        if (!uniqueTaskActivityIds.has(taskId)) {
          uniqueTaskActivityIds.add(taskId); // Add the taskId to the set
          return true; // Include this activity
        }
        return false; // Exclude this activity
      });
      
      // Concatenate the filtered newActivities with the previous activities
      return [...prevActivities, ...filteredNewActivities];
    });
    
    
    setLoading(false);
  }

  const fetchSystemNames = async () => {
    const systems = await DataStore.query(System);
    setSystemNames(systems);
  };

  const fetchTaskNames = async (systemId) => {
    if (systemId === 'All Systems') {
      setTaskNames([]);
      return;
    }

    const tasks = await DataStore.query(Task, (t) => t.systemId.eq(systemId));
    setTaskNames(tasks);
  };

  const handleSystemChange = (event) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      task: 'All Tasks',
      system: event.target.value,
    }));
  };
  const handleOptionChange = (event) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [event.target.name]: event.target.value,
    }));
  };

  const handleScroll = () => {
    if (
      containerRef.current &&
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight - 100 &&
      !loading &&
      hasMore
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    containerRef.current.addEventListener('scroll', handleScroll);
    return () => {
      try{
        containerRef.current && containerRef.current.removeEventListener('scroll', handleScroll);
      }
      catch (e){
        console.log(e);
      }
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1, py: 2 }}>
      <Container >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
          <Select name="system" value={selectedOptions.system} onChange={handleSystemChange}>
            <MenuItem value="All Systems">All Systems</MenuItem>
            {systemNames.map((system) => (
              <MenuItem key={system.id} value={system.id}>
                {system.name}
              </MenuItem>
            ))}
          </Select>
          {selectedOptions.system !== 'All Systems' && (
            <Select name="task" value={selectedOptions.task} onChange={handleOptionChange}>
              <MenuItem value="All Tasks">All Tasks</MenuItem>
              {taskNames.map((task) => (
                <MenuItem key={task.id} value={task.id}>
                  {task.title}
                </MenuItem>
              ))}
            </Select>
          )}
          <Select name="filter" value={selectedOptions.filter} onChange={handleOptionChange}>
            <MenuItem value="Show All">Show All</MenuItem>
            <MenuItem value="Show Missed">Show Missed</MenuItem>
            <MenuItem value="Show Activities">Show Completed</MenuItem>
          </Select>
        </Box>
        <Box
          ref={containerRef}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 200px)',
          }}
        >
          {activities.map((activity) => (
            <Box key={activity.taskactivity.id} sx={{ flexGrow: 1, minWidth: '300px' }}>
              <EntityActivity activity={activity} />
            </Box>
          ))}
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', width: '100%' }}>
              <SplashScreen fullscreen={false}></SplashScreen>
            </Box>
          )}
          {!loading && !hasMore && activities.length === 0 && (
            <Card sx={{ flexGrow: 1, minWidth: '300px' }}>
              <CardContent>
                <Typography variant="body1" align="center">
                  No activities to show.
                </Typography>
              </CardContent>
            </Card>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default EntityActivities;
